import React from 'react';
import classNames from 'classnames';

import { MFunctionReturnType, useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';

import { Button, IconCheck, BREAKPOINT_MAP } from '@darwinex/components-library';

import { HomeLayoutContext, HomeLayoutContextProps } from '~/storybook/layout/HomeLayoutContext';
import { getWelcomeUrl } from '~/utils/urls';
import Nullable from '~/types/Nullable';
import { someCountryBelongsToCNMV, userHasDiscountInFuturesAccount } from '~/utils/regulated-companies';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import getDefaultPriceByCurrency from '~/utils/currencies/default-price';
import { Currency } from '~/enums/Currency';
import getDefaultExtraCostForFuturesAccountByCurrency from '~/utils/currencies/default-extra-cost-futures-account';
import { PaymentFrequency } from '~/enums/PaymentFrequency';
import styles from './Growth.module.scss';

interface GrowthProps {
    requestOriginCountry: Nullable<string>;
    userCurrency: Currency;
}

const Growth: React.FC<GrowthProps> = ({ requestOriginCountry, userCurrency }) => {
    const homeLayoutContext = React.useContext(HomeLayoutContext) as HomeLayoutContextProps;
    const { openLink, profile } = homeLayoutContext;

    const { M } = useI18N();

    const { width } = useWindowSize();
    const isDesktop = width > BREAKPOINT_MAP.breakpoint_lg;

    const requestComesFromCountryUnderCNMVRegulation = someCountryBelongsToCNMV([requestOriginCountry]);

    const LIST_ITEMS: { title: MFunctionReturnType; subItems?: MFunctionReturnType[] }[] = [
        {
            title: M('page.home.index.growth.list-1'),
        },
        {
            title: M('page.home.index.growth.list-2'),
            subItems: [
                `${M('page.home.index.growth.sublist-1')}${requestComesFromCountryUnderCNMVRegulation ? '*' : ''}`,
                M('page.home.index.growth.sublist-2'),
                M('page.home.index.growth.sublist-3'),
            ],
        },
        {
            title: M('page.home.index.growth.list-3'),
        },
        {
            title: M('page.home.index.growth.list-4'),
        },
    ];

    return (
        <section className={classNames(styles.growth)}>
            <div className="dxcl-container">
                <h2 className={classNames(styles.growth__title)}>{M('page.home.index.growth.title')}</h2>
                <p className={classNames(styles.growth__subtitle)}>{M('page.home.index.growth.subtitle')}</p>
                <div className={classNames(styles.growth__subsection)}>
                    <h3 className={classNames(styles.growth__h3)}>{M('page.home.index.growth.text-1')}</h3>
                    <ul className={classNames(styles.growth__list)}>
                        {LIST_ITEMS.map((item, index) => {
                            const listKey = `grow-section-list-item-${index}`;
                            return (
                                <li key={listKey} className={classNames(styles['growth__list-item'])}>
                                    <p className={classNames(styles.growth__text)}>
                                        <IconCheck className={classNames(styles['growth__check--green'])} />
                                        <span>{item.title}</span>
                                    </p>
                                    {item.subItems !== undefined && (
                                        <ul className={classNames(styles.growth__sublist)}>
                                            {item.subItems.map((subItem, subIndex) => (
                                                <li
                                                    key={`${listKey}-subitem-${subIndex}`}
                                                    className={classNames(styles['growth__list-subitem'])}
                                                >
                                                    <p className={classNames(styles.growth__text)}>
                                                        <IconCheck
                                                            className={classNames(styles['growth__check--green'])}
                                                        />
                                                        <span>{subItem}</span>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                    <h3 className={classNames(styles.growth__subscription)}>
                        {`${M('page.home.index.growth.text-2', {
                            price: getAmountWithCurrencyFormat(
                                getDefaultPriceByCurrency(userCurrency, PaymentFrequency.MONTHLY),
                                userCurrency,
                            ),
                        })}${requestComesFromCountryUnderCNMVRegulation ? '**' : '*'}`}
                    </h3>
                    <div className={classNames(styles.growth__actions)}>
                        <Button
                            size={isDesktop ? 'z-lg' : 'z-xs'}
                            border="rect"
                            type="button"
                            variant="primary"
                            onClick={() => {
                                openLink(profile ? getWelcomeUrl(profile) : '/signup');
                            }}
                            className={classNames('dx-font-size-lg')}
                        >
                            {M('page.home.index.growth.cta-1')}
                        </Button>
                        <Button
                            size={isDesktop ? 'z-lg' : 'z-xs'}
                            border="rect"
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                openLink('/pricing');
                            }}
                            className={classNames('dx-font-size-lg')}
                        >
                            {M('page.home.index.growth.cta-2')}
                        </Button>
                    </div>
                    {requestComesFromCountryUnderCNMVRegulation && (
                        <p className={classNames(styles.growth__info, 'dx-pt-0 dx-pt-md-5')}>
                            *{M('page.home.index.growth.info-3')}
                        </p>
                    )}
                    <p className={classNames(styles.growth__info)}>
                        {requestComesFromCountryUnderCNMVRegulation ? '**' : '*'}
                        {M('page.home.index.growth.info')}
                        {!userHasDiscountInFuturesAccount([requestOriginCountry])
                            ? ` ${M('page.home.index.growth.info-4', {
                                  price: getAmountWithCurrencyFormat(
                                      getDefaultExtraCostForFuturesAccountByCurrency(userCurrency),
                                      userCurrency,
                                  ),
                              })}`
                            : ''}{' '}
                        {M('page.home.index.growth.info-5')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Growth;
