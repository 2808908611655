import React from 'react';
import classNames from 'classnames';

import { useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';
import { getVarColorFromProductName } from '~/utils/darwin-name';

import { BREAKPOINT_MAP, Button, DxNumber, IconPerformanceFees, IconUser } from '@darwinex/components-library';
import TagCard from '~/storybook/components/common/tag-card/TagCard';
import { HomeLayoutContext, HomeLayoutContextProps } from '~/storybook/layout/HomeLayoutContext';
import moment from 'moment';
import styles from './Millions.module.scss';
import Nullable from '../../../../../../types/Nullable';

export interface Props {
    products: Array<CardProductInfo>;
}

export interface CardProductInfo {
    productName: string;
    owner: Nullable<string>;
    flag: 'china' | 'spain' | 'switzerland' | 'japan' | 'finland';
    quote: Nullable<number>;
    returnSinceInception: Nullable<number>;
    firstQuoteDate: Nullable<number>;
    totalInvestment: Nullable<number>;
    totalInvestors: Nullable<number>;
    providerEarnings: Nullable<number>;
}

const Millions: React.FC<Props> = ({ products }: Props) => {
    const homeLayoutContext = React.useContext(HomeLayoutContext) as HomeLayoutContextProps;
    const { openLink } = homeLayoutContext;

    const { M } = useI18N();
    const { width } = useWindowSize();

    const isDesktop = width > BREAKPOINT_MAP.breakpoint_lg;

    return (
        <section className={classNames(styles.millions, 'dxcl-container')}>
            <div className={classNames('dxcl-row')}>
                <h2 className={classNames(styles.millions__title, 'dxcl-col-xl-24')}>
                    {M('page.home.index.millions.title')}
                </h2>
                <p className={classNames(styles.millions__subtitle, 'dxcl-col-xl-24')}>
                    {M('page.home.index.millions.subtitle')}
                </p>
            </div>
            <div className={classNames(styles.millions__cards, 'dxcl-row')}>
                {products.map((product) => (
                    <div
                        className={classNames('dxcl-col-lg-12 dxcl-col-xl-6')}
                        key={`darwin-card-${product.productName}`}
                    >
                        <div className={classNames(styles.millions__card)}>
                            <div className={classNames(styles['millions__card-header'])}>
                                {product.owner && (
                                    <TagCard
                                        productName={product.productName}
                                        owner={product.owner}
                                        flag={product.flag}
                                    />
                                )}
                            </div>
                            <div className={classNames(styles['millions__card-body'])}>
                                <div>
                                    <p>{M('page.home.index.millions.label-1')}</p>
                                    <p>
                                        <DxNumber value={product.quote} decimals={2} />
                                    </p>
                                </div>
                                <div>
                                    <p>{M('page.home.index.millions.label-2')}</p>
                                    <p>
                                        <span className="dx-mr-2">
                                            <DxNumber
                                                value={product.returnSinceInception}
                                                decimals={2}
                                                format="percent"
                                                colored
                                            />
                                        </span>
                                        <span className={classNames(styles['millions--small'], `dx-mr-2`)}>
                                            {M('page.home.index.millions.subtitle-2')}
                                        </span>
                                        {product.firstQuoteDate && (
                                            <span className={classNames(styles['millions--date'])}>
                                                {moment.utc(product.firstQuoteDate).format('MM/YYYY')}
                                            </span>
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <p>{M('page.home.index.millions.label-3')}</p>
                                    <p className={classNames(styles.millions__info)}>
                                        <DxNumber
                                            value={product.totalInvestment}
                                            format="currency"
                                            currency="USD"
                                            decimals={2}
                                            className="dx-no-wrap"
                                        />
                                        <span className="dx-no-wrap">
                                            <IconUser className={classNames(styles['millions__icon-user'])} />
                                            <DxNumber value={product.totalInvestors} decimals={0} />
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p>{M('page.home.index.millions.label-4')}</p>
                                    <p>
                                        <span>
                                            <span>
                                                <IconPerformanceFees
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    className={classNames(styles['millions__icon-fees'])}
                                                    color={`var(${getVarColorFromProductName(product.productName)})`}
                                                />
                                                <DxNumber
                                                    value={product.providerEarnings}
                                                    decimals={0}
                                                    format="currency"
                                                    currency="EUR"
                                                    sign
                                                />
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={classNames('dxcl-row dx-mr-0 dx-ml-0')}>
                <Button
                    size={isDesktop ? 'z-lg' : 'z-xs'}
                    border="rect"
                    type="button"
                    variant="secondary"
                    onClick={() => {
                        openLink('/why-us#darwinex-ecosystem');
                    }}
                    className={classNames(styles.millions__cta, 'dx-font-size-lg')}
                >
                    {M('page.home.index.millions.cta')}
                </Button>
            </div>
        </section>
    );
};

export default Millions;
