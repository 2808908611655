const IconBgZero = ({ className }: { className: string }) => (
    <svg viewBox="0 0 633 195" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            fill="#1E1727"
            d="M24.371.487v4.23L11.182 23.515h13.19v5.005H4v-4.23L17.028 5.492H4.53V.487h19.845-.004ZM35.289 23.518h12.256v5.005H29.648V.487H47.18v5.005H35.289v6.673h10.428v5.087H35.29v6.266ZM67.147 28.523 60.49 18.472h-1.907v10.05h-5.641V.488h11.16c6.413 0 9.17 4.556 9.17 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004ZM58.584 5.492v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.421-4.272-4.06-4.272h-4.95l.003-.003ZM76.884 14.525C76.884 6.55 83.134 0 91.088 0c7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M129.704.487v4.23l-13.189 18.797h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.499V.487h19.845-.003ZM140.622 23.518h12.256v5.005H134.98V.487h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM172.479 28.523l-6.656-10.051h-1.906v10.05h-5.641V.488h11.159c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.422-4.272-4.06-4.272h-4.951l.004-.003ZM182.216 14.525C182.216 6.55 188.466 0 196.42 0c7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.562 4.149-8.562 9.195s3.529 9.153 8.562 9.153c5.033 0 8.563-4.11 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M235.036.487v4.23l-13.189 18.797h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.498V.487h19.845-.004ZM245.954 23.518h12.256v5.005h-17.897V.487h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM277.812 28.523l-6.656-10.051h-1.907v10.05h-5.641V.488h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM287.549 14.525C287.549 6.55 293.798 0 301.753 0c7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M340.369.487v4.23L327.18 23.515h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499V.487h19.845-.003ZM351.286 23.518h12.257v5.005h-17.898V.487h17.532v5.005h-11.891v6.673h10.429v5.087h-10.429v6.266ZM383.144 28.523l-6.656-10.051h-1.906v10.05h-5.642V.488h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM392.881 14.525C392.881 6.55 399.131 0 407.085 0c7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.563-4.11 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M445.701.487v4.23l-13.189 18.797h13.189v5.005H425.33v-4.23l13.028-18.797H425.86V.487h19.845-.004ZM456.619 23.518h12.256v5.005h-17.897V.487h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM488.477 28.523l-6.656-10.051h-1.907v10.05h-5.641V.488h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM498.214 14.525C498.214 6.55 504.463 0 512.418 0c7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M551.034.487v4.23l-13.189 18.797h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499V.487h19.845-.003ZM561.952 23.518h12.256v5.005H556.31V.487h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM593.809 28.523l-6.656-10.051h-1.906v10.05h-5.642V.488h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM603.546 14.525C603.546 6.55 609.796 0 617.75 0c7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.563-4.11 8.563-9.153ZM24.372 33.496v4.23l-13.19 18.798h13.19v5.005H4V57.3L17.029 38.5h-12.5v-5.005h19.846-.003ZM35.29 56.528h12.256v5.005H29.648V33.495H47.18v5.005H35.29v6.674h10.428v5.087H35.289v6.266ZM67.148 61.532l-6.656-10.05h-1.907v10.05h-5.641V33.496h11.16c6.413 0 9.17 4.556 9.17 9.154 0 3.987-2.802 7.893-6.738 8.585l7.224 10.294h-6.616l.004.003Zm-8.563-23.03v8.423h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.238-1.421-4.272-4.06-4.272h-4.95l.003-.004ZM76.885 47.534c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.57-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M129.706 33.496v4.23l-13.189 18.798h13.189v5.005h-20.372V57.3L122.363 38.5h-12.499v-5.005h19.845-.003ZM140.624 56.528h12.256v5.005h-17.897V33.495h17.532v5.005h-11.891v6.674h10.428v5.087h-10.428v6.266ZM172.482 61.532l-6.656-10.05h-1.907v10.05h-5.641V33.496h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.003Zm-8.563-23.03v8.423h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.238-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM182.22 47.534c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M235.041 33.496v4.23l-13.19 18.798h13.19v5.005h-20.372V57.3L227.698 38.5h-12.499v-5.005h19.845-.003ZM245.958 56.528h12.257v5.005h-17.898V33.495h17.532v5.005h-11.891v6.674h10.429v5.087h-10.429v6.266ZM277.817 61.532l-6.656-10.05h-1.907v10.05h-5.641V33.496h11.159c6.414 0 9.171 4.556 9.171 9.154 0 3.987-2.801 7.893-6.738 8.585l7.224 10.294h-6.616l.004.003Zm-8.563-23.03v8.423h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.238-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM287.554 47.534c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M340.375 33.496v4.23l-13.189 18.798h13.189v5.005h-20.372V57.3L333.032 38.5h-12.499v-5.005h19.845-.003ZM351.293 56.528h12.256v5.005h-17.898V33.495h17.533v5.005h-11.891v6.674h10.428v5.087h-10.428v6.266ZM383.151 61.532l-6.656-10.05h-1.907v10.05h-5.641V33.496h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.003Zm-8.563-23.03v8.423h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.238-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM392.888 47.534c0-7.975 6.25-14.525 14.205-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.205-6.55-14.205-14.484Zm22.767 0c0-5.046-3.57-9.195-8.562-9.195-4.993 0-8.563 4.149-8.563 9.195s3.529 9.154 8.563 9.154c5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M445.709 33.496v4.23L432.52 56.525h13.189v5.005h-20.371V57.3L438.366 38.5h-12.499v-5.005h19.846-.004ZM456.627 56.528h12.257v5.005h-17.898V33.495h17.532v5.005h-11.891v6.674h10.429v5.087h-10.429v6.266ZM488.486 61.532l-6.657-10.05h-1.906v10.05h-5.642V33.496h11.16c6.414 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.003Zm-8.563-23.03v8.423h4.503c3.164 0 4.504-1.911 4.504-4.148 0-2.238-1.422-4.272-4.059-4.272h-4.952l.004-.004ZM498.223 47.534c0-7.975 6.249-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M551.044 33.496v4.23l-13.19 18.798h13.19v5.005h-20.372V57.3L543.701 38.5h-12.499v-5.005h19.845-.003ZM561.962 56.528h12.256v5.005H556.32V33.495h17.532v5.005h-11.89v6.674h10.428v5.087h-10.428v6.266ZM593.82 61.532l-6.656-10.05h-1.907v10.05h-5.641V33.496h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.739 8.585l7.224 10.294h-6.615l.003.003Zm-8.563-23.03v8.423h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.238-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM603.557 47.534c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.562 4.149-8.562 9.195s3.529 9.154 8.562 9.154c5.033 0 8.563-4.111 8.563-9.154ZM24.371 66.505v4.23L11.182 89.533h13.19v5.005H4v-4.23L17.028 71.51H4.53v-5.005h19.845-.004ZM35.289 89.536h12.256v5.005H29.648V66.505H47.18v5.005H35.289v6.673h10.428v5.087H35.29v6.266ZM67.147 94.54 60.49 84.49h-1.907v10.05h-5.641V66.506h11.16c6.413 0 9.17 4.556 9.17 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.03v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.421-4.272-4.06-4.272h-4.95l.003-.003ZM76.884 80.543c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M129.704 66.505v4.23l-13.189 18.797h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.499v-5.005h19.845-.003ZM140.622 89.536h12.256v5.005H134.98V66.505h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM172.479 94.54l-6.656-10.05h-1.906v10.05h-5.641V66.506h11.159c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.03v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.422-4.272-4.06-4.272h-4.951l.004-.003ZM182.216 80.543c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.562 4.149-8.562 9.195s3.529 9.153 8.562 9.153c5.033 0 8.563-4.11 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M235.036 66.505v4.23l-13.189 18.797h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.498v-5.005h19.845-.004ZM245.954 89.536h12.256v5.005h-17.897V66.505h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM277.812 94.54l-6.656-10.05h-1.907v10.05h-5.641V66.506h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.03v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM287.549 80.543c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M340.369 66.505v4.23L327.18 89.533h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499v-5.005h19.845-.003ZM351.286 89.536h12.257v5.005h-17.898V66.505h17.532v5.005h-11.891v6.673h10.429v5.087h-10.429v6.266ZM383.144 94.54l-6.656-10.05h-1.906v10.05h-5.642V66.506h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.03v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM392.881 80.543c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.563-4.11 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M445.701 66.505v4.23l-13.189 18.797h13.189v5.005H425.33v-4.23l13.028-18.797H425.86v-5.005h19.845-.004ZM456.619 89.536h12.256v5.005h-17.897V66.505h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM488.477 94.54l-6.656-10.05h-1.907v10.05h-5.641V66.506h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.03v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM498.214 80.543c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.562-4.11 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M551.034 66.505v4.23l-13.189 18.797h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499v-5.005h19.845-.003ZM561.952 89.536h12.256v5.005H556.31V66.505h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM593.809 94.54l-6.656-10.05h-1.906v10.05h-5.642V66.506h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.03v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM603.546 80.543c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.153 8.563 9.153c5.033 0 8.563-4.11 8.563-9.153ZM24.372 99.514v4.231l-13.19 18.797h13.19v5.005H4v-4.231l13.029-18.797h-12.5v-5.005h19.846-.003ZM35.29 122.546h12.256v5.005H29.648V99.514H47.18v5.005H35.29v6.674h10.428v5.087H35.289v6.266ZM67.148 127.551l-6.656-10.052h-1.907v10.052h-5.641V99.514h11.16c6.413 0 9.17 4.556 9.17 9.154 0 3.987-2.802 7.893-6.738 8.585l7.224 10.294h-6.616l.004.004Zm-8.563-23.032v8.424h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.237-1.421-4.272-4.06-4.272h-4.95l.003-.004ZM76.885 113.552c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.57-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M129.706 99.514v4.231l-13.189 18.797h13.189v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM140.624 122.546h12.256v5.005h-17.897V99.514h17.532v5.005h-11.891v6.674h10.428v5.087h-10.428v6.266ZM172.482 127.551l-6.656-10.052h-1.907v10.052h-5.641V99.514h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.563-23.032v8.424h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.237-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM182.22 113.552c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.195-8.562-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M235.041 99.514v4.231l-13.19 18.797h13.19v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM245.958 122.546h12.257v5.005h-17.898V99.514h17.532v5.005h-11.891v6.674h10.429v5.087h-10.429v6.266ZM277.817 127.551l-6.656-10.052h-1.907v10.052h-5.641V99.514h11.159c6.414 0 9.171 4.556 9.171 9.154 0 3.987-2.801 7.893-6.738 8.585l7.224 10.294h-6.616l.004.004Zm-8.563-23.032v8.424h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.237-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM287.554 113.552c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M340.375 99.514v4.231l-13.189 18.797h13.189v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM351.293 122.546h12.256v5.005h-17.898V99.514h17.533v5.005h-11.891v6.674h10.428v5.087h-10.428v6.266ZM383.151 127.551l-6.656-10.052h-1.907v10.052h-5.641V99.514h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.563-23.032v8.424h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.237-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM392.888 113.552c0-7.975 6.25-14.525 14.205-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.205-6.55-14.205-14.484Zm22.767 0c0-5.046-3.57-9.195-8.562-9.195-4.993 0-8.563 4.149-8.563 9.195s3.529 9.154 8.563 9.154c5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M445.709 99.514v4.231l-13.189 18.797h13.189v5.005h-20.371v-4.231l13.028-18.797h-12.499v-5.005h19.846-.004ZM456.627 122.546h12.257v5.005h-17.898V99.514h17.532v5.005h-11.891v6.674h10.429v5.087h-10.429v6.266ZM488.486 127.551l-6.657-10.052h-1.906v10.052h-5.642V99.514h11.16c6.414 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.032v8.424h4.503c3.164 0 4.504-1.911 4.504-4.148 0-2.237-1.422-4.272-4.059-4.272h-4.952l.004-.004ZM498.223 113.552c0-7.975 6.249-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.563 4.149-8.563 9.195s3.53 9.154 8.563 9.154c5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M551.044 99.514v4.231l-13.19 18.797h13.19v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM561.962 122.546h12.256v5.005H556.32V99.514h17.532v5.005h-11.89v6.674h10.428v5.087h-10.428v6.266ZM593.82 127.551l-6.656-10.052h-1.907v10.052h-5.641V99.514h11.16c6.413 0 9.171 4.556 9.171 9.154 0 3.987-2.802 7.893-6.739 8.585l7.224 10.294h-6.615l.003.004Zm-8.563-23.032v8.424h4.504c3.164 0 4.503-1.911 4.503-4.148 0-2.237-1.421-4.272-4.059-4.272h-4.951l.003-.004ZM603.557 113.552c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.195-8.563-9.195-4.992 0-8.562 4.149-8.562 9.195s3.529 9.154 8.562 9.154c5.033 0 8.563-4.111 8.563-9.154ZM24.371 132.523v4.231L11.182 155.55h13.19v5.005H4v-4.23l13.028-18.797H4.53v-5.005h19.845-.004ZM35.289 155.554h12.256v5.005H29.648v-28.036H47.18v5.005H35.289v6.673h10.428v5.087H35.29v6.266ZM67.147 160.559l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.16c6.413 0 9.17 4.556 9.17 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.031v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.421-4.272-4.06-4.272h-4.95l.003-.003ZM76.884 146.561c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.194-8.562-9.194-4.992 0-8.563 4.148-8.563 9.194 0 5.046 3.53 9.154 8.563 9.154 5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M129.704 132.523v4.231l-13.189 18.796h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.499v-5.005h19.845-.003ZM140.622 155.554h12.256v5.005H134.98v-28.036h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM172.479 160.559l-6.656-10.051h-1.906v10.051h-5.641v-28.036h11.159c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.422-4.272-4.06-4.272h-4.951l.004-.003ZM182.216 146.561c0-7.975 6.25-14.525 14.204-14.525 7.955 0 14.245 6.55 14.245 14.525 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.194-8.563-9.194-4.992 0-8.562 4.148-8.562 9.194 0 5.046 3.529 9.154 8.562 9.154 5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M235.036 132.523v4.231l-13.189 18.796h13.189v5.005h-20.371v-4.23l13.028-18.797h-12.498v-5.005h19.845-.004ZM245.954 155.554h12.256v5.005h-17.897v-28.036h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM277.812 160.559l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM287.549 146.561c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.194-8.562-9.194-4.992 0-8.563 4.148-8.563 9.194 0 5.046 3.53 9.154 8.563 9.154 5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M340.369 132.523v4.231L327.18 155.55h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499v-5.005h19.845-.003ZM351.286 155.554h12.257v5.005h-17.898v-28.036h17.532v5.005h-11.891v6.673h10.429v5.087h-10.429v6.266ZM383.144 160.559l-6.656-10.051h-1.906v10.051h-5.642v-28.036h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM392.881 146.561c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.194-8.563-9.194-4.992 0-8.563 4.148-8.563 9.194 0 5.046 3.53 9.154 8.563 9.154 5.033 0 8.563-4.111 8.563-9.154Z"
        />
        <path
            fill="#1E1727"
            d="M445.701 132.523v4.231l-13.189 18.796h13.189v5.005H425.33v-4.23l13.028-18.797H425.86v-5.005h19.845-.004ZM456.619 155.554h12.256v5.005h-17.897v-28.036h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM488.477 160.559l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.004.004Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM498.214 146.561c0-7.975 6.249-14.525 14.204-14.525 7.954 0 14.244 6.55 14.244 14.525 0 7.975-6.331 14.484-14.244 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.046-3.57-9.194-8.562-9.194-4.992 0-8.563 4.148-8.563 9.194 0 5.046 3.53 9.154 8.563 9.154 5.033 0 8.562-4.111 8.562-9.154Z"
        />
        <path
            fill="#262435"
            d="M551.034 132.523v4.231l-13.189 18.796h13.189v5.005h-20.372v-4.23l13.029-18.797h-12.499v-5.005h19.845-.003ZM561.952 155.554h12.256v5.005H556.31v-28.036h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM593.809 160.559l-6.656-10.051h-1.906v10.051h-5.642v-28.036h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.294h-6.615l.003.004Zm-8.562-23.031v8.424h4.503c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.004-.003ZM603.546 146.561c0-7.975 6.25-14.525 14.204-14.525 7.954 0 14.245 6.55 14.245 14.525 0 7.975-6.332 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.046-3.571-9.194-8.563-9.194-4.992 0-8.563 4.148-8.563 9.194 0 5.046 3.53 9.154 8.563 9.154 5.033 0 8.563-4.111 8.563-9.154ZM24.372 165.533v4.231l-13.19 18.797h13.19v5.005H4v-4.231l13.029-18.797h-12.5v-5.005h19.846-.003ZM35.29 188.564h12.256v5.005H29.648v-28.036H47.18v5.005H35.29v6.673h10.428v5.087H35.289v6.266ZM67.148 193.569l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.16c6.413 0 9.17 4.556 9.17 9.153 0 3.988-2.802 7.893-6.738 8.585l7.224 10.295h-6.616l.004.003Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.06-4.272h-4.95l.003-.003ZM76.885 179.571c0-7.976 6.25-14.526 14.204-14.526 7.955 0 14.245 6.55 14.245 14.526 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.047-3.57-9.195-8.563-9.195-4.992 0-8.563 4.148-8.563 9.195 0 5.046 3.53 9.153 8.563 9.153 5.033 0 8.563-4.111 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M129.706 165.533v4.231l-13.189 18.797h13.189v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM140.624 188.564h12.256v5.005h-17.897v-28.036h17.532v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM172.482 193.569l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.295h-6.615l.003.003Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM182.22 179.571c0-7.976 6.249-14.526 14.204-14.526 7.954 0 14.245 6.55 14.245 14.526 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.766 0c0-5.047-3.57-9.195-8.562-9.195-4.992 0-8.563 4.148-8.563 9.195 0 5.046 3.53 9.153 8.563 9.153 5.033 0 8.562-4.111 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M235.041 165.533v4.231l-13.19 18.797h13.19v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM245.958 188.564h12.257v5.005h-17.898v-28.036h17.532v5.005h-11.891v6.673h10.429v5.087h-10.429v6.266ZM277.817 193.569l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.159c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.801 7.893-6.738 8.585l7.224 10.295h-6.616l.004.003Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM287.554 179.571c0-7.976 6.25-14.526 14.204-14.526 7.955 0 14.245 6.55 14.245 14.526 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.047-3.571-9.195-8.563-9.195-4.992 0-8.563 4.148-8.563 9.195 0 5.046 3.53 9.153 8.563 9.153 5.033 0 8.563-4.111 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M340.375 165.533v4.231l-13.189 18.797h13.189v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM351.293 188.564h12.256v5.005h-17.898v-28.036h17.533v5.005h-11.891v6.673h10.428v5.087h-10.428v6.266ZM383.151 193.569l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.295h-6.615l.003.003Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM392.888 179.571c0-7.976 6.25-14.526 14.205-14.526 7.954 0 14.245 6.55 14.245 14.526 0 7.975-6.332 14.484-14.245 14.484-7.914 0-14.205-6.55-14.205-14.484Zm22.767 0c0-5.047-3.57-9.195-8.562-9.195-4.993 0-8.563 4.148-8.563 9.195 0 5.046 3.529 9.153 8.563 9.153 5.033 0 8.562-4.111 8.562-9.153Z"
        />
        <path
            fill="#262435"
            d="M445.709 165.533v4.231l-13.189 18.797h13.189v5.005h-20.371v-4.231l13.028-18.797h-12.499v-5.005h19.846-.004ZM456.627 188.564h12.257v5.005h-17.898v-28.036h17.532v5.005h-11.891v6.673h10.429v5.087h-10.429v6.266ZM488.486 193.569l-6.657-10.051h-1.906v10.051h-5.642v-28.036h11.16c6.414 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.738 8.585l7.223 10.295h-6.615l.004.003Zm-8.563-23.031v8.424h4.503c3.164 0 4.504-1.912 4.504-4.149s-1.422-4.272-4.059-4.272h-4.952l.004-.003ZM498.223 179.571c0-7.976 6.249-14.526 14.204-14.526 7.955 0 14.245 6.55 14.245 14.526 0 7.975-6.331 14.484-14.245 14.484-7.914 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.047-3.571-9.195-8.563-9.195-4.992 0-8.563 4.148-8.563 9.195 0 5.046 3.53 9.153 8.563 9.153 5.033 0 8.563-4.111 8.563-9.153Z"
        />
        <path
            fill="#1E1727"
            d="M551.044 165.533v4.231l-13.19 18.797h13.19v5.005h-20.372v-4.231l13.029-18.797h-12.499v-5.005h19.845-.003ZM561.962 188.564h12.256v5.005H556.32v-28.036h17.532v5.005h-11.89v6.673h10.428v5.087h-10.428v6.266ZM593.82 193.569l-6.656-10.051h-1.907v10.051h-5.641v-28.036h11.16c6.413 0 9.171 4.556 9.171 9.153 0 3.988-2.802 7.893-6.739 8.585l7.224 10.295h-6.615l.003.003Zm-8.563-23.031v8.424h4.504c3.164 0 4.503-1.912 4.503-4.149s-1.421-4.272-4.059-4.272h-4.951l.003-.003ZM603.557 179.571c0-7.976 6.25-14.526 14.204-14.526 7.955 0 14.245 6.55 14.245 14.526 0 7.975-6.331 14.484-14.245 14.484-7.913 0-14.204-6.55-14.204-14.484Zm22.767 0c0-5.047-3.571-9.195-8.563-9.195-4.992 0-8.562 4.148-8.562 9.195 0 5.046 3.529 9.153 8.562 9.153 5.033 0 8.563-4.111 8.563-9.153Z"
        />
    </svg>
);
export default IconBgZero;
