const IconBgDarwina = ({ className }: { className: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        aria-labelledby="logoTitle logoDesc"
        focusable="false"
        role="img"
        width="100%"
        height="100%"
        viewBox="0 0 632 227"
        className={className}
    >
        <title id="logoTitle">Darwina Gold, Darwina Silver</title>
        <desc id="logoDesc">Darwina Gold, Darwina Silver</desc>
        <mask id="a" width="632" height="227" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
            <path fill="#D9D9D9" d="M0 0h632v227H0z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#E8A700"
                d="M323.468-5.029C319.767-9.286 314.091-12 307.49-12c-12.154 0-21.778 9.81-21.778 21.84 0 11.968 9.624 21.777 21.778 21.777 12.215 0 20.728-7.033 20.728-22.95h-21.839v7.342h11.413c0 4.442-5.737 7.341-10.302 7.341-7.157 0-12.709-6.17-12.709-13.51 0-7.404 5.552-13.45 12.709-13.45 3.701 0 6.909 1.666 9.315 4.319l6.663-5.738ZM340.164 9.84c0-7.095 5.429-12.895 12.524-12.895 7.033 0 12.585 5.8 12.585 12.894 0 7.095-5.552 12.833-12.585 12.833-7.095 0-12.524-5.738-12.524-12.833Zm-9.007 0c0 11.968 9.501 21.777 21.531 21.777 12.092 0 21.592-9.81 21.592-21.778 0-12.03-9.5-21.839-21.592-21.839-12.03 0-21.531 9.81-21.531 21.84ZM380.636 31h24.986v-7.958h-15.917v-34.549h-9.069V31ZM419.966-3.301h7.65c6.724 0 10.179 6.107 10.179 13.14 0 6.972-3.455 12.956-10.179 12.956h-7.65V-3.301ZM427.616 31c12.153 0 19.186-9.069 19.186-21.16 0-12.092-7.033-21.347-19.186-21.347h-16.657V31h16.657Z"
            />
            <path
                fill="#333047"
                d="M.243-3.58h7.85c6.9 0 10.445 6.267 10.445 13.483 0 7.154-3.546 13.295-10.446 13.295H.242V-3.58Zm7.85 35.197c12.47 0 19.687-9.306 19.687-21.714C27.78-2.504 20.563-12 8.092-12H-9v43.617H8.092ZM44.305 13.259l4.431-15.194 4.621 15.194h-9.052Zm12.028 8.04 3.861 10.318h9.75L53.8-12H43.735L27.529 31.617h9.875l3.862-10.319h15.067ZM90.132-3.834c3.545 0 5.255 3.419 5.255 6.14 0 2.786-1.9 6.141-5.508 6.141h-7.85v-12.28h8.103Zm4.052 20.005c7.47-1.52 10.888-7.724 10.888-13.864 0-7.154-4.621-14.307-14.496-14.307H72.724v43.617h9.305V16.55h2.09l9.748 15.067h11.142L94.184 16.171ZM143.464 15.474l-5.761-21.333h-7.596c-1.963 7.09-3.862 14.433-5.824 21.333L115.8-12h-10.002l14.687 43.617h7.343l6.014-22.916 6.14 22.916h7.28L162.076-12H152.01l-8.546 27.474ZM165.018-12v43.617h9.243V-12h-9.243ZM215.179-12h-9.306v26.082L190.933-12h-8.483v43.617h9.243V5.219l15.193 26.398h8.293V-12ZM223.374-12v43.617h9.242V-12h-9.242ZM252.073 13.259l4.432-15.194 4.621 15.194h-9.053Zm12.028 8.04 3.862 10.318h9.749L261.569-12h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066Z"
            />
            <path
                fill="#262435"
                d="m471.152 13.11 4.319-14.807 4.504 14.808h-8.823Zm11.723 7.836 3.763 10.057h9.501l-15.732-42.51h-9.81l-15.795 42.51h9.625l3.764-10.057h14.684ZM500.84 31.003h24.987v-7.959h-15.918v-34.55h-9.069v42.509ZM532.351 31.003h24.987v-7.959H541.42v-34.55h-9.069v42.509ZM569.438 9.84c0-7.094 5.43-12.894 12.525-12.894 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.095 0-12.525-5.738-12.525-12.833Zm-9.008 0c0 11.97 9.502 21.78 21.533 21.78 12.092 0 21.594-9.81 21.594-21.78 0-12.03-9.502-21.84-21.594-21.84-12.031 0-21.533 9.81-21.533 21.84ZM640.591 17.244c-2.283 3.456-6.17 5.862-10.612 5.862-7.095 0-12.278-5.923-12.278-13.265 0-7.404 5.183-13.327 12.278-13.327 4.442 0 8.082 2.16 10.365 5.553l7.342-5.06C643.984-8.421 637.629-12 629.979-12c-12.093 0-21.348 9.81-21.348 21.84 0 11.97 9.255 21.78 21.348 21.78 7.712 0 14.19-3.64 17.892-9.316l-7.28-5.06ZM665.221 13.11l4.319-14.807 4.504 14.808h-8.823Zm11.723 7.836 3.763 10.057h9.502l-15.733-42.51h-9.81l-15.794 42.51h9.624l3.764-10.057h14.684ZM688.265-3.362h11.476v34.365h9.131V-3.362h11.475v-8.144h-32.082v8.144ZM727.083-11.506v42.509h9.008v-42.51h-9.008ZM752.602 9.84c0-7.094 5.429-12.894 12.525-12.894 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.096 0-12.525-5.738-12.525-12.833Zm-9.008 0c0 11.97 9.502 21.78 21.533 21.78 12.092 0 21.594-9.81 21.594-21.78 0-12.03-9.502-21.84-21.594-21.84-12.031 0-21.533 9.81-21.533 21.84ZM826.161-11.506h-9.07v25.419l-14.56-25.42h-8.268v42.51h9.008V5.275l14.807 25.728h8.083v-42.51Z"
            />
            <path
                fill="#768A98"
                d="M370 70.112c0 7.64 6.222 13.122 15.401 13.122 7.578 0 15.094-4.066 15.279-12.444.185-4.005-1.356-9.857-11.644-12.814l-5.051-1.602c-4.19-1.17-4.559-3.204-4.559-4.312 0-2.526 2.341-4.251 5.483-4.251 3.634 0 5.483 1.91 5.483 4.805h8.994c0-8.132-6.161-12.999-14.354-12.999-8.132 0-14.601 5.114-14.601 12.691 0 3.881 1.602 9.24 10.966 12.198l5.113 1.417c4.251 1.232 5.175 2.895 5.114 4.805-.062 2.773-2.526 4.497-6.223 4.497-4.127 0-6.16-2.402-6.345-5.113H370ZM406.951 40.11v42.447h8.995V40.11h-8.995ZM424.718 82.556h24.95V74.61h-15.894V40.11h-9.056v42.447ZM447.444 40.11l12.937 42.447h8.009l12.937-42.447h-9.426l-7.516 27.846-7.577-27.846h-9.364ZM486.12 82.556h27.415V74.61h-18.421v-9.056h15.648V57.73h-15.648v-9.672h17.866V40.11h-26.86v42.447ZM537.372 48.057c3.45 0 5.114 3.327 5.114 5.976 0 2.71-1.848 5.976-5.36 5.976h-7.639V48.057h7.885Zm3.943 19.468c7.27-1.479 10.596-7.516 10.596-13.492 0-6.962-4.497-13.923-14.107-13.923h-17.373v42.447h9.056V67.894h2.033l9.487 14.662h10.843l-10.535-15.031Z"
            />
            <path
                fill="#333047"
                d="M568.869 48.037h7.85c6.9 0 10.446 6.267 10.446 13.484 0 7.153-3.546 13.294-10.446 13.294h-7.85V48.037Zm7.85 35.197c12.471 0 19.688-9.306 19.688-21.713 0-12.408-7.217-21.904-19.688-21.904h-17.092v43.617h17.092ZM612.932 64.876l4.431-15.193 4.621 15.193h-9.052Zm12.027 8.04 3.862 10.318h9.749l-16.143-43.617h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066ZM658.759 47.783c3.545 0 5.255 3.419 5.255 6.141 0 2.785-1.9 6.14-5.508 6.14h-7.85v-12.28h8.103Zm4.052 20.005c7.47-1.52 10.888-7.723 10.888-13.864 0-7.153-4.621-14.307-14.497-14.307H641.35v43.617h9.306V68.168h2.089l9.749 15.066h11.142l-10.825-15.446ZM712.091 67.091l-5.761-21.333h-7.596c-1.963 7.09-3.862 14.433-5.824 21.333l-8.483-27.474h-10.002l14.686 43.617h7.344l6.014-22.916 6.14 22.916h7.28l14.814-43.617h-10.066l-8.546 27.474ZM733.645 39.617v43.617h9.243V39.617h-9.243ZM783.806 39.617H774.5V65.7l-14.94-26.082h-8.483v43.617h9.242V56.836l15.194 26.398h8.293V39.617ZM792.001 39.617v43.617h9.242V39.617h-9.242ZM820.7 64.876l4.432-15.193 4.621 15.193H820.7Zm12.028 8.04 3.862 10.318h9.749l-16.143-43.617h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066Z"
            />
            <path
                fill="#262435"
                d="m7.35 65.11 4.319-14.806 4.503 14.807H7.35Zm11.722 7.836 3.764 10.057h9.501l-15.733-42.51h-9.81L-9 83.004H.625l3.763-10.057h14.684ZM37.037 83.003h24.988v-7.959H46.107v-34.55h-9.07v42.509ZM68.549 83.003h24.987v-7.959H77.618v-34.55h-9.07v42.509ZM105.636 61.84c0-7.094 5.429-12.894 12.525-12.894 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.096 0-12.525-5.738-12.525-12.833Zm-9.008 0c0 11.97 9.502 21.78 21.533 21.78 12.092 0 21.594-9.81 21.594-21.78 0-12.03-9.502-21.84-21.594-21.84-12.031 0-21.533 9.81-21.533 21.84ZM176.788 69.245c-2.282 3.455-6.169 5.86-10.612 5.86-7.095 0-12.277-5.922-12.277-13.264 0-7.404 5.182-13.327 12.277-13.327 4.443 0 8.083 2.16 10.366 5.553l7.342-5.06C180.182 43.579 173.827 40 166.176 40c-12.092 0-21.347 9.81-21.347 21.84 0 11.97 9.255 21.78 21.347 21.78 7.713 0 14.191-3.64 17.893-9.316l-7.281-5.06ZM201.419 65.11l4.319-14.806 4.504 14.807h-8.823Zm11.723 7.836 3.763 10.057h9.502l-15.733-42.51h-9.81l-15.795 42.51h9.625l3.764-10.057h14.684ZM224.463 48.638h11.475v34.365h9.132V48.638h11.475v-8.144h-32.082v8.144ZM263.281 40.494v42.509h9.008v-42.51h-9.008ZM288.8 61.84c0-7.094 5.429-12.894 12.524-12.894 7.034 0 12.587 5.8 12.587 12.895s-5.553 12.833-12.587 12.833c-7.095 0-12.524-5.738-12.524-12.833Zm-9.008 0c0 11.97 9.501 21.78 21.532 21.78 12.093 0 21.594-9.81 21.594-21.78 0-12.03-9.501-21.84-21.594-21.84-12.031 0-21.532 9.81-21.532 21.84ZM362.359 40.494h-9.07v25.419l-14.561-25.42h-8.267v42.51h9.008V57.275l14.807 25.728h8.083v-42.51Z"
            />
            <path
                fill="#768A98"
                d="M-9 121.729c0 7.639 6.222 13.122 15.401 13.122 7.578 0 15.094-4.066 15.279-12.444.185-4.004-1.356-9.857-11.644-12.814l-5.051-1.602c-4.19-1.17-4.56-3.203-4.56-4.312 0-2.526 2.342-4.251 5.484-4.251 3.634 0 5.483 1.91 5.483 4.805h8.994c0-8.132-6.16-12.999-14.354-12.999-8.132 0-14.6 5.114-14.6 12.691 0 3.881 1.601 9.241 10.965 12.198l5.113 1.417c4.251 1.232 5.175 2.896 5.114 4.805-.062 2.773-2.526 4.498-6.223 4.498-4.127 0-6.16-2.403-6.345-5.114H-9ZM27.951 91.727v42.447h8.995V91.727H27.95ZM45.718 134.174h24.95v-7.947H54.774v-34.5h-9.056v42.447ZM68.444 91.727l12.937 42.447h8.009l12.937-42.447h-9.426l-7.516 27.846-7.577-27.846h-9.364ZM107.12 134.174h27.415v-7.947h-18.421v-9.057h15.648v-7.823h-15.648v-9.673h17.866v-7.947h-26.86v42.447ZM158.372 99.674c3.45 0 5.114 3.327 5.114 5.976 0 2.711-1.848 5.976-5.36 5.976h-7.639V99.674h7.885Zm3.943 19.468c7.27-1.479 10.596-7.516 10.596-13.492 0-6.961-4.497-13.923-14.107-13.923h-17.373v42.447h9.056v-14.662h2.033l9.487 14.662h10.843l-10.535-15.032Z"
            />
            <path
                fill="#E8A700"
                d="M513.38 98.206c-3.702-4.257-9.378-6.972-15.979-6.972-12.153 0-21.777 9.81-21.777 21.84 0 11.968 9.624 21.777 21.777 21.777 12.215 0 20.729-7.033 20.729-22.949h-21.839v7.341h11.413c0 4.442-5.738 7.342-10.303 7.342-7.156 0-12.709-6.17-12.709-13.511 0-7.403 5.553-13.45 12.709-13.45 3.702 0 6.91 1.666 9.316 4.319l6.663-5.737ZM530.076 113.074c0-7.095 5.429-12.894 12.523-12.894 7.033 0 12.586 5.799 12.586 12.894 0 7.094-5.553 12.832-12.586 12.832-7.094 0-12.523-5.738-12.523-12.832Zm-9.008 0c0 11.968 9.501 21.777 21.531 21.777 12.092 0 21.593-9.809 21.593-21.777 0-12.03-9.501-21.84-21.593-21.84-12.03 0-21.531 9.81-21.531 21.84ZM570.547 134.234h24.986v-7.958h-15.917V91.728h-9.069v42.506ZM609.878 99.933h7.649c6.725 0 10.18 6.108 10.18 13.141 0 6.971-3.455 12.955-10.18 12.955h-7.649V99.933Zm7.649 34.301c12.154 0 19.187-9.068 19.187-21.16s-7.033-21.346-19.187-21.346H600.87v42.506h16.657Z"
            />
            <path
                fill="#333047"
                d="M190.154 99.654h7.85c6.9 0 10.445 6.267 10.445 13.484 0 7.153-3.545 13.294-10.445 13.294h-7.85V99.654Zm7.85 35.197c12.471 0 19.688-9.305 19.688-21.713 0-12.408-7.217-21.904-19.688-21.904h-17.092v43.617h17.092ZM234.216 116.493l4.432-15.193 4.621 15.193h-9.053Zm12.028 8.04 3.862 10.318h9.749l-16.143-43.617h-10.066l-16.206 43.617h9.876l3.862-10.318h15.066ZM280.044 99.4c3.545 0 5.254 3.419 5.254 6.141 0 2.786-1.899 6.141-5.507 6.141h-7.85V99.401h8.103Zm4.051 20.005c7.47-1.519 10.889-7.723 10.889-13.864 0-7.153-4.621-14.307-14.497-14.307h-17.852v43.617h9.306v-15.066h2.089l9.749 15.066h11.142l-10.826-15.446ZM333.376 118.709l-5.761-21.334h-7.597c-1.962 7.09-3.861 14.433-5.824 21.334l-8.482-27.475h-10.003l14.687 43.617h7.344l6.013-22.916 6.141 22.916h7.28l14.813-43.617h-10.065l-8.546 27.475ZM354.93 91.234v43.617h9.243V91.234h-9.243ZM405.09 91.234h-9.306v26.082l-14.94-26.082h-8.482v43.617h9.242v-26.398l15.193 26.398h8.293V91.234ZM413.285 91.234v43.617h9.243V91.234h-9.243ZM441.985 116.493l4.431-15.193 4.622 15.193h-9.053Zm12.028 8.04 3.862 10.318h9.748l-16.142-43.617h-10.066l-16.206 43.617h9.876l3.861-10.318h15.067Z"
            />
            <path
                fill="#768A98"
                d="M369.715 173.347c0 7.639 6.223 13.122 15.402 13.122 7.577 0 15.093-4.066 15.278-12.445.185-4.004-1.355-9.857-11.643-12.814l-5.052-1.602c-4.189-1.17-4.559-3.203-4.559-4.312 0-2.526 2.341-4.251 5.483-4.251 3.635 0 5.483 1.91 5.483 4.805h8.994c0-8.132-6.16-12.998-14.354-12.998-8.132 0-14.6 5.113-14.6 12.69 0 3.882 1.601 9.241 10.965 12.198l5.114 1.417c4.251 1.232 5.175 2.896 5.113 4.806-.062 2.772-2.526 4.497-6.222 4.497-4.128 0-6.161-2.403-6.346-5.113h-9.056ZM406.667 143.344v42.447h8.994v-42.447h-8.994ZM424.433 185.791h24.95v-7.947h-15.894v-34.5h-9.056v42.447ZM447.159 143.344l12.937 42.447h8.009l12.937-42.447h-9.425l-7.516 27.846-7.578-27.846h-9.364ZM485.835 185.791h27.415v-7.947h-18.42v-9.056h15.648v-7.824H494.83v-9.672h17.865v-7.948h-26.86v42.447ZM537.088 151.292c3.45 0 5.113 3.326 5.113 5.975 0 2.711-1.848 5.976-5.36 5.976h-7.639v-11.951h7.886Zm3.943 19.467c7.269-1.478 10.596-7.516 10.596-13.492 0-6.961-4.497-13.923-14.108-13.923h-17.373v42.447h9.056v-14.662h2.033l9.488 14.662h10.842l-10.534-15.032Z"
            />
            <path
                fill="#333047"
                d="M568.869 151.271h7.85c6.9 0 10.446 6.267 10.446 13.484 0 7.153-3.546 13.294-10.446 13.294h-7.85v-26.778Zm7.85 35.198c12.471 0 19.688-9.306 19.688-21.714s-7.217-21.903-19.688-21.903h-17.092v43.617h17.092ZM612.932 168.11l4.431-15.193 4.621 15.193h-9.052Zm12.027 8.04 3.862 10.319h9.749l-16.143-43.617h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066ZM658.759 151.018c3.545 0 5.255 3.418 5.255 6.14 0 2.786-1.9 6.141-5.508 6.141h-7.85v-12.281h8.103Zm4.052 20.004c7.47-1.519 10.888-7.723 10.888-13.864 0-7.153-4.621-14.306-14.497-14.306H641.35v43.617h9.306v-15.067h2.089l9.749 15.067h11.142l-10.825-15.447ZM712.091 170.326l-5.761-21.334h-7.596c-1.963 7.09-3.862 14.434-5.824 21.334l-8.483-27.474h-10.002l14.686 43.617h7.344l6.014-22.917 6.14 22.917h7.28l14.814-43.617h-10.066l-8.546 27.474ZM733.645 142.852v43.617h9.243v-43.617h-9.243ZM783.806 142.852H774.5v26.081l-14.94-26.081h-8.483v43.617h9.242V160.07l15.194 26.399h8.293v-43.617ZM792.001 142.852v43.617h9.242v-43.617h-9.242ZM820.7 168.11l4.432-15.193 4.621 15.193H820.7Zm12.028 8.04 3.862 10.319h9.749l-16.143-43.617h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066Z"
            />
            <path
                fill="#262435"
                d="m7.35 167.111 4.319-14.808 4.503 14.808H7.35Zm11.722 7.835 3.764 10.057h9.501l-15.733-42.509h-9.81L-9 185.003H.625l3.763-10.057h14.684ZM37.037 185.003h24.988v-7.959H46.107v-34.55h-9.07v42.509ZM68.549 185.003h24.987v-7.959H77.618v-34.55h-9.07v42.509ZM105.636 163.841c0-7.095 5.429-12.895 12.525-12.895 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.096 0-12.525-5.738-12.525-12.833Zm-9.008 0c0 11.969 9.502 21.779 21.533 21.779 12.092 0 21.594-9.81 21.594-21.779 0-12.031-9.502-21.841-21.594-21.841-12.031 0-21.533 9.81-21.533 21.841ZM176.788 171.245c-2.282 3.455-6.169 5.861-10.612 5.861-7.095 0-12.277-5.923-12.277-13.265 0-7.404 5.182-13.327 12.277-13.327 4.443 0 8.083 2.16 10.366 5.553l7.342-5.059c-3.702-5.43-10.057-9.008-17.708-9.008-12.092 0-21.347 9.81-21.347 21.841 0 11.969 9.255 21.779 21.347 21.779 7.713 0 14.191-3.64 17.893-9.316l-7.281-5.059ZM201.419 167.111l4.319-14.808 4.504 14.808h-8.823Zm11.723 7.835 3.763 10.057h9.502l-15.733-42.509h-9.81l-15.795 42.509h9.625l3.764-10.057h14.684ZM224.463 150.638h11.475v34.365h9.132v-34.365h11.475v-8.144h-32.082v8.144ZM263.281 142.494v42.509h9.008v-42.509h-9.008ZM288.8 163.841c0-7.095 5.429-12.895 12.524-12.895 7.034 0 12.587 5.8 12.587 12.895s-5.553 12.833-12.587 12.833c-7.095 0-12.524-5.738-12.524-12.833Zm-9.008 0c0 11.969 9.501 21.779 21.532 21.779 12.093 0 21.594-9.81 21.594-21.779 0-12.031-9.501-21.841-21.594-21.841-12.031 0-21.532 9.81-21.532 21.841ZM362.359 142.494h-9.07v25.419l-14.561-25.419h-8.267v42.509h9.008v-25.728l14.807 25.728h8.083v-42.509Z"
            />
            <path
                fill="#E8A700"
                d="M28.756 201.44c-3.701-4.257-9.377-6.971-15.978-6.971C.624 194.469-9 204.278-9 216.308c0 11.969 9.624 21.778 21.778 21.778 12.215 0 20.729-7.033 20.729-22.95h-21.84v7.341H23.08c0 4.442-5.737 7.342-10.302 7.342-7.157 0-12.71-6.169-12.71-13.511 0-7.403 5.553-13.449 12.71-13.449 3.701 0 6.91 1.666 9.315 4.319l6.663-5.738ZM45.452 216.308c0-7.095 5.43-12.894 12.524-12.894 7.033 0 12.585 5.799 12.585 12.894S65.01 229.14 57.976 229.14c-7.095 0-12.524-5.737-12.524-12.832Zm-9.007 0c0 11.969 9.5 21.778 21.53 21.778 12.093 0 21.593-9.809 21.593-21.778 0-12.03-9.5-21.839-21.592-21.839-12.03 0-21.531 9.809-21.531 21.839ZM85.924 237.469h24.986v-7.959H94.993v-34.548h-9.069v42.507ZM125.254 203.167h7.65c6.724 0 10.179 6.108 10.179 13.141 0 6.971-3.455 12.956-10.179 12.956h-7.65v-26.097Zm7.65 34.302c12.153 0 19.186-9.069 19.186-21.161s-7.033-21.346-19.186-21.346h-16.657v42.507h16.657Z"
            />
            <path
                fill="#333047"
                d="M548.691 202.888h7.85c6.9 0 10.445 6.267 10.445 13.484 0 7.154-3.545 13.294-10.445 13.294h-7.85v-26.778Zm7.85 35.198c12.471 0 19.688-9.306 19.688-21.714s-7.217-21.903-19.688-21.903h-17.092v43.617h17.092ZM592.753 219.727l4.432-15.193 4.621 15.193h-9.053Zm12.028 8.04 3.862 10.319h9.749l-16.143-43.617h-10.065l-16.206 43.617h9.875l3.862-10.319h15.066ZM638.581 202.635c3.545 0 5.254 3.419 5.254 6.141 0 2.785-1.899 6.14-5.507 6.14h-7.85v-12.281h8.103Zm4.052 20.004c7.47-1.519 10.888-7.723 10.888-13.863 0-7.154-4.621-14.307-14.497-14.307h-17.852v43.617h9.306v-15.067h2.089l9.749 15.067h11.142l-10.825-15.447ZM691.913 221.943l-5.761-21.334h-7.596c-1.963 7.09-3.862 14.434-5.824 21.334l-8.483-27.474h-10.002l14.686 43.617h7.344l6.014-22.917 6.14 22.917h7.28l14.814-43.617h-10.066l-8.546 27.474ZM713.467 194.469v43.617h9.243v-43.617h-9.243ZM763.627 194.469h-9.305v26.081l-14.94-26.081h-8.483v43.617h9.242v-26.398l15.193 26.398h8.293v-43.617ZM771.822 194.469v43.617h9.243v-43.617h-9.243ZM800.522 219.727l4.431-15.193 4.622 15.193h-9.053Zm12.028 8.04 3.862 10.319h9.749l-16.143-43.617h-10.066l-16.206 43.617h9.876l3.861-10.319h15.067Z"
            />
            <path
                fill="#262435"
                d="m176.44 220.111 4.319-14.808 4.504 14.808h-8.823Zm11.723 7.835 3.763 10.057h9.502l-15.733-42.509h-9.81l-15.795 42.509h9.625l3.764-10.057h14.684ZM206.128 238.003h24.987v-7.959h-15.918v-34.55h-9.069v42.509ZM237.639 238.003h24.987v-7.959h-15.917v-34.55h-9.07v42.509ZM274.726 216.841c0-7.095 5.43-12.895 12.525-12.895 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.095 0-12.525-5.738-12.525-12.833Zm-9.007 0c0 11.969 9.501 21.779 21.532 21.779 12.093 0 21.594-9.81 21.594-21.779 0-12.031-9.501-21.841-21.594-21.841-12.031 0-21.532 9.81-21.532 21.841ZM345.879 224.245c-2.283 3.455-6.17 5.861-10.612 5.861-7.095 0-12.278-5.923-12.278-13.265 0-7.404 5.183-13.327 12.278-13.327 4.442 0 8.082 2.16 10.365 5.553l7.342-5.059c-3.702-5.43-10.057-9.008-17.707-9.008-12.093 0-21.347 9.81-21.347 21.841 0 11.969 9.254 21.779 21.347 21.779 7.712 0 14.19-3.64 17.892-9.316l-7.28-5.059ZM370.509 220.111l4.319-14.808 4.504 14.808h-8.823Zm11.723 7.835 3.764 10.057h9.501l-15.733-42.509h-9.81l-15.794 42.509h9.624l3.764-10.057h14.684ZM393.553 203.638h11.476v34.365h9.131v-34.365h11.476v-8.144h-32.083v8.144ZM432.371 195.494v42.509h9.008v-42.509h-9.008ZM457.89 216.841c0-7.095 5.43-12.895 12.525-12.895 7.033 0 12.586 5.8 12.586 12.895s-5.553 12.833-12.586 12.833c-7.095 0-12.525-5.738-12.525-12.833Zm-9.008 0c0 11.969 9.502 21.779 21.533 21.779 12.092 0 21.594-9.81 21.594-21.779 0-12.031-9.502-21.841-21.594-21.841-12.031 0-21.533 9.81-21.533 21.841ZM531.449 195.494h-9.07v25.419l-14.56-25.419h-8.268v42.509h9.008v-25.728l14.807 25.728h8.083v-42.509Z"
            />
        </g>
    </svg>
);

export default IconBgDarwina;
