const IconZero = ({ className }: { className: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        aria-labelledby="logoTitle logoDesc"
        focusable="false"
        role="img"
        width="100%"
        height="100%"
        viewBox="0 0 287 83"
        className={className}
    >
        <title id="logoTitle">Zero</title>
        <desc id="logoDesc">Logo Zero</desc>
        <path
            // fill="#44454F"
            d="M57.902 1.691v11.972L20.58 66.853h37.322v14.163H.257V69.044l36.867-53.19H1.755V1.691h56.156-.01ZM88.797 66.863h34.681v14.162H72.834V1.691h49.61v14.163H88.797v18.883h29.509v14.396h-29.51v17.73ZM178.945 81.025 160.11 52.584h-5.395v28.441h-15.963V1.691h31.578c18.148 0 25.951 12.893 25.951 25.902 0 11.284-7.928 22.335-19.067 24.293l20.44 29.13h-18.719l.01.01Zm-24.23-65.171v23.837h12.744c8.953 0 12.743-5.41 12.743-11.74s-4.022-12.087-11.487-12.087h-14.01l.01-.01ZM206.499 41.415c0-22.567 17.684-41.102 40.192-41.102 22.509 0 40.31 18.535 40.31 41.102 0 22.567-17.917 40.985-40.31 40.985-22.392 0-40.192-18.534-40.192-40.985Zm64.422 0c0-14.279-10.104-26.018-24.23-26.018-14.126 0-24.229 11.74-24.229 26.018 0 14.28 9.987 25.902 24.229 25.902 14.243 0 24.23-11.633 24.23-25.902Z"
        />
    </svg>
);

export default IconZero;
