export const enum Modals {
    SEE_CREDENTIALS = 'SEE_CREDENTIALS',
    DOWNLOAD_TERMINAL = 'DOWNLOAD_TERMINAL',
    SHARE_SOCIAL_MEDIA = 'SHARE_SOCIAL_MEDIA',
    VIDEO_TUTORIAL = 'VIDEO_TUTORIAL',
    CALCULATOR = 'CALCULATOR',
    GOLD_PROGRESS = 'GOLD_PROGRESS',
    COMPARISON_TABLE = 'COMPARISON_TABLE',
    PRIVACY_SETTINGS = 'PRIVACY_SETTINGS',
    CAPACITY = 'CAPACITY',
    PLATFORM_REVIEW = 'PLATFORM_REVIEW',
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
}
