/* eslint-disable react/Tag-has-type */
import React from 'react';
import classNames from 'classnames';

import { IconDarwinexOwl } from '@darwinex/components-library';
import { getShortName, getVarColorFromProductName } from '~/utils/darwin-name';
import styles from './TagCard.module.scss';

export interface Props {
    productName: string;
    owner: string;
    className?: string;
    flag: 'china' | 'spain' | 'switzerland' | 'japan' | 'finland';
}

const TagCard = ({ productName, owner, className, flag }: Props) => (
    <div className={styles.tag} style={{ borderBottom: `1 px solid var(${getVarColorFromProductName(productName)})` }}>
        <div className={classNames(styles.tag__left)}>
            <div
                className={styles.tag__rectangle}
                style={{ backgroundColor: `var(${getVarColorFromProductName(productName)})` }}
            >
                <IconDarwinexOwl className={classNames(styles.tag__icon)} />
                <p className={classNames(styles.tag__title, ``)}>{getShortName(productName)}</p>
            </div>
            <div
                className={styles.tag__triangle}
                style={{
                    borderColor: `var(${getVarColorFromProductName(productName)}) transparent transparent transparent`,
                }}
            />
        </div>
        <div className={classNames(styles.tag__right)}>
            <span className={styles.tag__username} style={{ color: `var(${getVarColorFromProductName(productName)}` }}>
                {owner || '---'}
            </span>
            <span className={classNames(styles.tag__flag, styles[`tag__flag--${flag}`])} />
        </div>
    </div>
);

export default TagCard;
