import React from 'react';

import { Modal } from '@darwinex/components-library';
import classNames from 'classnames';

import styles from '~/storybook/pages/platform/darwin-page/modals/video-tutorial/VideoTutorial.module.scss';
import Nullable from '~/types/Nullable';

interface Props {
    onModalClose: React.MouseEventHandler;
    title: Nullable<string>;
    url: Nullable<string>;
}

const VideoTutorial = ({ onModalClose, title, url }: Props) => {
    const youTubeUrl = 'https://www.youtube.com/embed/';

    return (
        <Modal size="xxl" title={title || '---'} onModalClose={onModalClose}>
            <div className="dx-font-size-lg">
                <div>
                    <iframe
                        src={youTubeUrl + url}
                        title={title || '---'}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className={classNames(styles.videoTutorial__video)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default VideoTutorial;
