import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import { useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';

import { BREAKPOINT_MAP, IconChevronRounded } from '@darwinex/components-library';

import ImageZeroScreen from '~/public/images/screen.svg';
import ImageZeroScreen2 from '~/public/images/screen-alt.svg';
import IconBgZero from '~/storybook/icons/IconBgZero';
import IconBgDarwina from '~/storybook/icons/IconBgDarwina';
import IconZero from '~/storybook/icons/IconZero';
import Nullable from '~/types/Nullable';

import styles from './Banner.module.scss';

interface Props {
    showFCAInfo: Nullable<boolean>;
}

const Banner = ({ showFCAInfo }: Props) => {
    const { M, ULink } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_sm;

    const myArray = [...Array(9).keys()];

    const numbers = () =>
        myArray.map((v) => (
            <tr key={v}>
                <td>{v}</td>
            </tr>
        ));

    return (
        <section className={classNames(styles.banner, 'dxcl-container')}>
            <div className={classNames('dxcl-row dx-mb-5')}>
                <div className={classNames('dxcl-col-xl-24')}>
                    <section className={classNames(styles.banner__card)}>
                        {!isMobile && <ULink href="/why-us" className={classNames(styles.banner__link_block)}></ULink>}
                        <div className={classNames('dxcl-row')}>
                            <div className={classNames('dxcl-col-xl-12')}>
                                <div className={classNames(styles.banner__wrapper_card)}>
                                    <p className={styles.banner__title}>{M('page.home.index.banner1.title')}</p>
                                    <p className={styles.banner__subtitle}>{M('page.home.index.banner1.subtitle')}</p>
                                    <p className={classNames(styles.banner__text)}>
                                        {M('page.home.index.banner1.text')}
                                    </p>
                                    <p className={classNames(styles.banner__link)}>
                                        {isMobile ? (
                                            <ULink href="/why-us">{M('page.home.index.banner1.link')}</ULink>
                                        ) : (
                                            <>{M('page.home.index.banner1.link')}</>
                                        )}
                                        <IconChevronRounded
                                            className={classNames(styles.banner__chevron)}
                                            width="16"
                                            height="16"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className={classNames('dxcl-col-xl-12')}>
                                <IconZero className={styles.banner__icon_zero} />
                                <figure className={styles.banner__figure}>
                                    <Image
                                        quality={100}
                                        alt="zero screen"
                                        src={ImageZeroScreen}
                                        className={styles.banner__screen}
                                        width={466}
                                        height={357}
                                        unoptimized
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                    <Image
                                        quality={100}
                                        alt="zero screen alt"
                                        src={ImageZeroScreen2}
                                        className={styles.banner__screen_alt}
                                        width={466}
                                        height={357}
                                        unoptimized
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                </figure>
                                <IconZero className={classNames(styles.banner__icon_zero_secondary)} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className={classNames('dxcl-row')}>
                <div className={classNames('dxcl-col-xl-12 dx-mb-5 dx-mb-xl-0')}>
                    <section className={classNames(styles.banner__card)}>
                        {!isMobile && (
                            <ULink href="/your-darwin-index" className={classNames(styles.banner__link_block)}></ULink>
                        )}
                        <div className={classNames(styles.banner__wrapper_card)}>
                            <p className={classNames(styles.banner__title, styles.banner__wrapper_text)}>
                                {M('page.home.index.banner3.title')}
                            </p>
                            <p className={classNames(styles.banner__subtitle, styles.banner__wrapper_text)}>
                                {M('page.home.index.banner3.subtitle')}
                            </p>
                            <p className={classNames(styles.banner__link)}>
                                {isMobile ? (
                                    <ULink href="/your-darwin-index">{M('page.home.index.banner3.link')}</ULink>
                                ) : (
                                    <>{M('page.home.index.banner3.link')}</>
                                )}
                                <IconChevronRounded
                                    className={classNames(styles.banner__chevron)}
                                    width="16"
                                    height="16"
                                />
                            </p>
                            <figure className={styles.banner__figure_track}>
                                <div className={styles.banner__numbers}>
                                    <span className={styles.banner__circle}></span>
                                    <span className={styles.banner__letters}>JTLA</span>
                                    <p className={styles.banner__mobile_numbers}>
                                        135.81 <span className={styles.banner__triangle}></span>
                                        0.44%
                                    </p>
                                    <div className={styles.banner__wrapper_numbers}>
                                        <div className={classNames(styles.banner__number, styles.banner__number1)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <div className={classNames(styles.banner__number, styles.banner__number3)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <div className={classNames(styles.banner__number, styles.banner__number4)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <span className={styles.banner__number}>.</span>
                                        <div className={classNames(styles.banner__number, styles.banner__number8)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <div className={classNames(styles.banner__number, styles.banner__number1)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <span className={styles.banner__triangle}></span>
                                        <div className={classNames(styles.banner__number, styles.banner__number0)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <span className={styles.banner__number}>.</span>
                                        <div className={classNames(styles.banner__number, styles.banner__number4)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <div className={classNames(styles.banner__number, styles.banner__number4)}>
                                            <table>
                                                <tbody>{numbers()}</tbody>
                                            </table>
                                        </div>
                                        <span className={styles.banner__number}>%</span>
                                    </div>
                                </div>
                                <IconBgZero className={styles.banner__bg_zero} />
                            </figure>
                        </div>
                    </section>
                </div>
                <div className={classNames('dxcl-col-xl-12')}>
                    <section className={classNames(styles.banner__card)}>
                        <div className={classNames(styles.banner__wrapper_card)}>
                            <p className={classNames(styles.banner__title, styles.banner__wrapper_text)}>
                                {M('page.home.index.banner2.title')}
                            </p>
                            <p className={classNames(styles.banner__subtitle, styles.banner__wrapper_text)}>
                                {M(`page.home.index.banner2.subtitle${showFCAInfo ? '-dx-link' : ''}`)}
                            </p>
                            <p className={classNames(styles.banner__link)}>
                                <ULink href="/capital-allocation">{M('page.home.index.banner2.link')}</ULink>
                                <IconChevronRounded
                                    className={classNames(styles.banner__chevron)}
                                    width="16"
                                    height="16"
                                />
                            </p>
                            <IconBgDarwina className={styles.banner__bg_darwina} />
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

export default Banner;
