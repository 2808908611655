const DEFAULT_MONTHLY_VALUE_EUR = 38;
const DEFAULT_MONTHLY_VALUE_USD = 43;

const DEFAULT_YEARLY_VALUE_EUR = 405;
const DEFAULT_YEARLY_VALUE_USD = 460;

const DEFAULT_THREE_YEARS_VALUE_EUR = 1095;
const DEFAULT_THREE_YEARS_VALUE_USD = 1240;

const DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_EUR = 5;
const DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_USD = 6;

export {
    DEFAULT_MONTHLY_VALUE_EUR,
    DEFAULT_MONTHLY_VALUE_USD,
    DEFAULT_YEARLY_VALUE_EUR,
    DEFAULT_YEARLY_VALUE_USD,
    DEFAULT_THREE_YEARS_VALUE_EUR,
    DEFAULT_THREE_YEARS_VALUE_USD,
    DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_EUR,
    DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_USD,
};
