import React from 'react';
import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import styles from './SectionFeatures.module.scss';

const SectionFeatures = () => {
    const { M } = useI18N();

    return (
        <section className={classNames(styles.section_features, 'dxcl-container')}>
            <div className={classNames(styles.section_features__wrapper, 'dxcl-row')}>
                <div className={classNames(styles.section_features__block, 'dxcl-col-xl-8')}>
                    <h3 className={styles.section_features__title}>{M('page.home.index.features.title-1')}</h3>
                    <p className={styles.section_features__text}>{M('page.home.index.features.description-1')}</p>
                </div>
                <div className={classNames(styles.section_features__block, 'dxcl-col-xl-8')}>
                    <h3 className={styles.section_features__title}>{M('page.home.index.features.title-2')}</h3>
                    <p className={styles.section_features__text}>{M('page.home.index.features.description-2')}</p>
                </div>
                <div className={classNames(styles.section_features__block, 'dxcl-col-xl-8')}>
                    <h3 className={styles.section_features__title}>{M('page.home.index.features.title-3')}</h3>
                    <p className={styles.section_features__text}>{M('page.home.index.features.description-3')}</p>
                </div>
            </div>
        </section>
    );
};

export default SectionFeatures;
