import { Currency } from '~/enums/Currency';
import { DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_EUR, DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_USD } from '~/constants/prices';

const getDefaultExtraCostForFuturesAccountByCurrency = (currency: Currency): number => {
    if (currency === Currency.EUR) {
        return DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_EUR;
    }
    return DEFAULT_EXTRA_COST_FUTURES_ACCOUNT_USD;
};

export default getDefaultExtraCostForFuturesAccountByCurrency;
