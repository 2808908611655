import React from 'react';
import { Button, BREAKPOINT_MAP, IconVideo } from '@darwinex/components-library';
import classNames from 'classnames';
import { useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';
import { HomeLayoutContext, HomeLayoutContextProps } from '~/storybook/layout/HomeLayoutContext';
import { getWelcomeUrl } from '~/utils/urls';
import { Currency } from '~/enums/Currency';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import getDefaultPriceByCurrency from '~/utils/currencies/default-price';
import { PaymentFrequency } from '~/enums/PaymentFrequency';
import styles from './Hero.module.scss';

export interface Props {
    seePlatformReview: () => void;
    userCurrency: Currency;
}

const Hero: React.FC<Props> = ({ seePlatformReview, userCurrency }) => {
    const homeLayoutContext = React.useContext(HomeLayoutContext) as HomeLayoutContextProps;
    const { openLink, profile } = homeLayoutContext;

    const { M } = useI18N();

    const { width } = useWindowSize();
    const isDesktop = width > BREAKPOINT_MAP.breakpoint_lg;

    return (
        <section className={styles.hero}>
            <div className={classNames(styles.hero__wrapper, 'dxcl-container')}>
                <div className={classNames(styles.hero__wrapper, 'dxcl-row')}>
                    <div className={classNames(styles.hero__wrapper_text, 'dxcl-col-xl-14')}>
                        <h1 className={styles.hero__title}>{M('page.home.index.hero.title')}</h1>
                        <h2 className={styles.hero__subtitle}>
                            {M('page.home.index.hero.subTitle', {
                                price: getAmountWithCurrencyFormat(
                                    getDefaultPriceByCurrency(userCurrency, PaymentFrequency.MONTHLY),
                                    userCurrency,
                                ),
                            })}
                        </h2>
                        <div className={classNames(styles.hero__wrapper_button)}>
                            <Button
                                size={isDesktop ? 'z-lg' : 'z-xs'}
                                border="rect"
                                type="button"
                                variant="primary"
                                className={styles.hero__button}
                                onClick={() => {
                                    openLink(profile ? getWelcomeUrl(profile) : '/signup');
                                }}
                            >
                                {M('page.home.index.hero.getStarted')}
                            </Button>
                            <Button
                                size={isDesktop ? 'z-lg' : 'z-xs'}
                                border="rect"
                                type="button"
                                variant="secondary"
                                className={classNames(
                                    styles.hero__button,
                                    'd-flex row align-items-center justify-content-center',
                                )}
                                onClick={() => seePlatformReview()}
                            >
                                <IconVideo height={22} width={22} className="dx-mr-2" />
                                {M('page.home.index.hero.platform-preview')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <video autoPlay loop muted playsInline className={styles.hero__video}>
                <source src="/video/hero-video-comp-2.webm" type="video/webm" />
                <source src="/video/hero-video.mp4" type="video/mp4" />
                {M('page.home.index.hero.video')}
            </video>
        </section>
    );
};

export default Hero;
