import { Currency } from '~/enums/Currency';
import Nullable from '~/types/Nullable';

const getCurrencyByCountry = (countriesWithEURCurrencyByDefault: string[], countryCode: Nullable<string>): Currency => {
    if (countryCode !== null) {
        return countriesWithEURCurrencyByDefault.includes(countryCode) ? Currency.EUR : Currency.USD;
    }
    return Currency.USD;
};

export default getCurrencyByCountry;
