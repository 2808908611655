import useFetch from '~/hooks/useFetch';
import { checkProductNameFormat } from '~/utils/darwin-name';
import Pageable from '~/types/dto/Pageable';
import { InvestorPerformanceFeesDto } from '~/types/dto/performance-fees/InvestorPerformanceFeesInfoDto';
import { DxCurrencies } from '@darwinex/components-library/build/components/DxNumber/number';
import Nullable from '../../types/Nullable';

interface State<T> {
    data: T;
    status?: number;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const PERFORMANCE_FEES_MS = '/api/performance-fees';

interface PFeesAggregatedByPeriodData {
    user: {
        periods: PeriodData;
        total: number;
    };
    darwinia: {
        periods: PeriodData;
        total: number;
    };
    currencyTrader: string;
}

interface PeriodData {
    [key: string]: number;
}

interface PFeesEstimatedData {
    investorName: string;
    estimatedPfeeAmount: number;
    traderCurrency: string;
    quarterEndDate: number;
    investment: number;
    totalPnl: number;
    hwmValue: number;
    performanceFeePercentage: number;
    estimationDate: number;
}

interface PFeesInvestorInfoDto {
    investorName: string;
    investment: Nullable<number>;
    totalPnl: Nullable<number>;
    lastHwmValue: Nullable<number>;
    varHwmPercentage: Nullable<number>;
    totalReset: Nullable<number>;
    investorCurrency: DxCurrencies;
}

interface TraderEarnings {
    position: Nullable<number>;
    earnings: Nullable<number>;
}

export const usePerformanceFeesService = () => {
    const useGetHistoricalPFees = (
        productName: Nullable<string>,
        aggregationPeriod: 'Y' | 'M',
    ): UseFetchReturnType<PFeesAggregatedByPeriodData> => {
        const { data, status, error, execute } = useFetch<PFeesAggregatedByPeriodData>(
            `${PERFORMANCE_FEES_MS}/products/${productName}/aggregated-by-date?aggregationPeriod=${aggregationPeriod}`,
        );
        return {
            data: data || {
                user: {
                    periods: {},
                    total: 0,
                },
                darwinia: {
                    periods: {},
                    total: 0,
                },
                currencyTrader: 'EUR',
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetForecastPFees = (productName: Nullable<string>): UseFetchReturnType<PFeesEstimatedData[]> => {
        const { data, status, error, execute } = useFetch<PFeesEstimatedData[]>(
            `${PERFORMANCE_FEES_MS}/products/${productName}/estimated-pfees`,
        );
        return {
            data: data || [],
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetInvestorsInfo = (
        productName: Nullable<string>,
        investorName: Nullable<string>,
    ): UseFetchReturnType<Pageable<PFeesInvestorInfoDto>> => {
        let URL = `${PERFORMANCE_FEES_MS}/products/${productName}/investors`;
        if (investorName) {
            URL += `?investorName=${investorName}`;
        }
        const { data, status, error, execute } = useFetch<Pageable<PFeesInvestorInfoDto>>(URL);

        return {
            data: data || {
                last: true,
                number: 0,
                totalElements: 0,
                totalPages: 0,
                numberOfElements: 0,
                size: 0,
                first: true,
                sort: {
                    sorted: false,
                    unsorted: true,
                    empty: true,
                },
                content: [],
            },
            status,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetTraderEarnings = (
        username: Nullable<string>,
    ): UseFetchReturnType<TraderEarnings & { username: string }> => {
        const { data, error, execute } = useFetch<TraderEarnings>(
            `${PERFORMANCE_FEES_MS}/halloffame/${username}/summary`,
        );
        return {
            data: data
                ? {
                      ...data,
                      username: username || '',
                  }
                : {
                      earnings: null,
                      position: null,
                      username: username || '',
                  },
            error,
            execute: () => {
                if (username) {
                    execute();
                }
            },
        };
    };

    const useGetInvestorPerformanceFeesInfo = (
        productName: Nullable<string>,
        page: number,
        perPage: number,
        order: Nullable<{
            field: string;
            direction: 'ASC' | 'DESC';
        }>,
        type: Nullable<'ALL' | 'ACTIVE'>,
        investorName: Nullable<string>,
    ): UseFetchReturnType<Pageable<InvestorPerformanceFeesDto>> => {
        let URL = `${PERFORMANCE_FEES_MS}/products/${productName}/investors?page=${page}&per_page=${perPage}`;
        if (type) {
            URL += `&type=${type}`;
        }
        if (investorName) {
            URL += `&investorName=${investorName}`;
        }
        if (order) {
            URL += `&sort=${order.field},${order.direction}`;
        }
        const { data, error, execute } = useFetch<Pageable<InvestorPerformanceFeesDto>>(URL);
        return {
            data: data || {
                last: true,
                number: 0,
                totalElements: 0,
                totalPages: 0,
                numberOfElements: 0,
                size: 0,
                first: true,
                sort: {
                    sorted: false,
                    unsorted: true,
                    empty: true,
                },
                content: [],
            },
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    return {
        getHistoricalPFees: useGetHistoricalPFees,
        getForecastPFees: useGetForecastPFees,
        getInvestorsInfo: useGetInvestorsInfo,
        getTraderEarnings: useGetTraderEarnings,
        getInvestorsPerformanceFeesInfo: useGetInvestorPerformanceFeesInfo,
    };
};
