import React from 'react';
import classNames from 'classnames';

import { useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';
import {
    Button,
    IconArrow,
    IconChartBar,
    IconCompetition,
    IconDarwin,
    IconDarwiniaLogo,
    BREAKPOINT_MAP,
} from '@darwinex/components-library';
import Image from 'next/image';

import ImageArrow from '~/public/images/arrow.png';
import { HomeLayoutContext, HomeLayoutContextProps } from '~/storybook/layout/HomeLayoutContext';
import { getWelcomeUrl } from '~/utils/urls';
import Nullable from '~/types/Nullable';
import { formatAmountThousandMillion } from '~/utils/format';
import styles from './Trading.module.scss';

export interface Props {
    competitionSilverMinAllocation: Nullable<number>;
    competitionSilverMaxAllocation: Nullable<number>;
}

const Trading: React.FC<Props> = ({ competitionSilverMinAllocation, competitionSilverMaxAllocation }: Props) => {
    const homeLayoutContext = React.useContext(HomeLayoutContext) as HomeLayoutContextProps;
    const { openLink, profile } = homeLayoutContext;

    const { M } = useI18N();
    const { width } = useWindowSize();
    const isDesktop = width > BREAKPOINT_MAP.breakpoint_lg;

    return (
        <section className={classNames(styles.trading, 'dxcl-container')}>
            <div className={classNames('dxcl-row')}>
                <h2 className={classNames(styles.trading__title, 'dxcl-col-xl-24')}>
                    {M('page.home.index.trading.title')}
                </h2>
                <p className={classNames(styles.trading__subtitle, 'dxcl-col-xl-24')}>
                    {M('page.home.index.trading.subtitle')}
                </p>
            </div>
            <div className={classNames(styles.trading__cards)}>
                <div className={classNames(styles['trading__card-section'])}>
                    <div className={classNames(styles.trading__card, styles['trading__card--first'])}>
                        <p className={classNames(styles['trading__card-stage'])}>
                            {M('page.home.index.trading.card-0-stage')}
                        </p>
                        <p className={classNames(styles['trading__card-title'], 'color-primary-500')}>
                            {M('page.home.index.trading.card-0-title-color')}
                        </p>
                        <div className={classNames(styles['trading__card-icon'], styles['trading__card-icon--orange'])}>
                            <IconChartBar className="color-primary-500" />
                        </div>
                        <p className={classNames(styles['trading__card-info'])}>
                            {M('page.home.index.trading.card-0-info')}
                        </p>

                        <Button
                            size="z-xs"
                            border="rect"
                            type="button"
                            variant="primary"
                            className={classNames(styles['trading__card-cta'], 'dx-font-size-md dx-p-5')}
                            onClick={() => {
                                openLink(profile ? getWelcomeUrl(profile) : '/signup');
                            }}
                        >
                            {M('page.home.index.trading.card-0-cta')}
                        </Button>
                    </div>
                    <IconArrow className={classNames(styles['trading__arrow-short'])} />
                </div>
                <p className={classNames(styles['trading__hidden-desk'])}> {M('page.home.index.trading.section-1')}</p>
                <div className={classNames(styles['trading__card-section'])}>
                    <div className={classNames(styles['trading__card-subsection'])}>
                        <div className={classNames(styles.trading__card)}>
                            <p className={classNames(styles['trading__card-stage'])}>
                                {M('page.home.index.trading.card-1-stage')}
                            </p>
                            <p className={classNames(styles['trading__card-title'])}>
                                {M('page.home.index.trading.card-1-title')}
                                <span className="color-extra-silver dx-ml-2">
                                    {M('page.home.index.trading.card-1-title-color')}
                                </span>
                            </p>
                            <div className={classNames(styles['trading__card-iconDarwinia'])}>
                                {/* <IconPodiumStar
                                    className="color-extra-silver"
                                    width={32}
                                    height={32}
                                    viewBox="0 0 24 24"
                                /> */}
                                <IconDarwiniaLogo className="color-extra-silver" width={80} height={80} />
                            </div>
                            <p className={classNames(styles['trading__card-info'])}>
                                {M('page.home.index.trading.card-1-info', {
                                    minAllocation: competitionSilverMinAllocation
                                        ? `€${formatAmountThousandMillion(competitionSilverMinAllocation)}`
                                        : '---',
                                    maxAllocation: competitionSilverMaxAllocation
                                        ? `€${formatAmountThousandMillion(competitionSilverMaxAllocation)}`
                                        : '---',
                                })}
                            </p>
                        </div>
                        <div className={classNames(styles.trading__card)}>
                            <p className={classNames(styles['trading__card-stage'])}>
                                {M('page.home.index.trading.card-2-stage')}
                            </p>
                            <p className={classNames(styles['trading__card-title'])}>
                                {M('page.home.index.trading.card-2-title')}
                                <span className="color-extra-gold dx-ml-2">
                                    {M('page.home.index.trading.card-2-title-color')}
                                </span>
                            </p>
                            <div className={classNames(styles['trading__card-iconDarwinia'])}>
                                {/* <IconPodiumStar
                                    className="color-extra-gold"
                                    width={32}
                                    height={32}
                                    viewBox="0 0 24 24"
                                /> */}
                                <IconDarwiniaLogo className="color-extra-gold" width={80} height={80} />
                            </div>
                            <p className={classNames(styles['trading__card-info'])}>
                                {M('page.home.index.trading.card-2-info')}
                            </p>
                        </div>
                    </div>
                    <div className={classNames(styles['trading__section-bottom'])}>
                        <Image
                            quality={100}
                            alt="arrow"
                            src={ImageArrow}
                            className={classNames(styles['trading__arrow-long'])}
                            width={500}
                            height={10}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                            }}
                        />
                        <p className={classNames(styles['trading__hidden-mobile'])}>
                            {M('page.home.index.trading.section-1')}
                        </p>
                        <IconArrow className={classNames(styles['trading__arrow-short'])} />
                        <p className={classNames(styles['trading__hidden-desk'])}>
                            {M('page.home.index.trading.section-2')}
                        </p>
                    </div>
                </div>
                <div className={classNames(styles['trading__card-section'])}>
                    <div className={classNames(styles['trading__card-subsection'])}>
                        <div className={classNames(styles.trading__card, '')}>
                            <p className={classNames(styles['trading__card-stage'])}>
                                {M('page.home.index.trading.card-3-stage')}
                            </p>
                            <p className={classNames(styles['trading__card-title'], styles.blue)}>
                                {M('page.home.index.trading.card-3-title-color')}
                            </p>
                            <div
                                className={classNames(styles['trading__card-icon'], styles['trading__card-icon--blue'])}
                            >
                                <IconDarwin
                                    className={classNames(styles.blue)}
                                    width={32}
                                    height={32}
                                    viewBox="0 0 24 24"
                                />
                            </div>
                            <p className={classNames(styles['trading__card-info'])}>
                                {M('page.home.index.trading.card-3-info')}
                            </p>
                        </div>
                        <div className={classNames(styles.trading__card, '')}>
                            <p className={classNames(styles['trading__card-stage'])}>
                                {M('page.home.index.trading.card-4-stage')}
                            </p>
                            <p className={classNames(styles['trading__card-title'])}>
                                {M('page.home.index.trading.card-4-title')}
                                <span className={classNames(styles.white)}>
                                    {M('page.home.index.trading.card-4-title-color')}
                                </span>
                            </p>
                            <div
                                className={classNames(
                                    styles['trading__card-icon'],
                                    styles['trading__card-icon--white'],
                                )}
                            >
                                <IconCompetition
                                    className={classNames(styles.white)}
                                    width={32}
                                    height={32}
                                    viewBox="0 0 24 24"
                                />
                            </div>
                            <p className={classNames(styles['trading__card-info'])}>
                                {M('page.home.index.trading.card-4-info')}
                            </p>
                        </div>
                    </div>
                    <div className={classNames(styles['trading__section-bottom'])}>
                        <Image
                            quality={100}
                            alt="arrow"
                            src={ImageArrow}
                            className={classNames(styles['trading__arrow-long'])}
                            width={500}
                            height={10}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                            }}
                        />
                        <p className={classNames(styles['trading__hidden-mobile'])}>
                            {M('page.home.index.trading.section-2')}
                        </p>
                    </div>
                </div>
            </div>
            <div className={classNames('dxcl-row dx-mt-5')}>
                <Button
                    size={isDesktop ? 'z-lg' : 'z-xs'}
                    border="rect"
                    type="button"
                    variant="secondary"
                    onClick={() => {
                        openLink('/capital-allocation');
                    }}
                    className={classNames(styles.trading__cta, 'dx-font-size-lg')}
                >
                    {M('page.home.index.trading.cta')}
                </Button>
            </div>
        </section>
    );
};

export default Trading;
