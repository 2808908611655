import { Currency } from '~/enums/Currency';
import {
    DEFAULT_MONTHLY_VALUE_EUR,
    DEFAULT_MONTHLY_VALUE_USD,
    DEFAULT_YEARLY_VALUE_EUR,
    DEFAULT_YEARLY_VALUE_USD,
    DEFAULT_THREE_YEARS_VALUE_EUR,
    DEFAULT_THREE_YEARS_VALUE_USD,
} from '~/constants/prices';
import { PaymentFrequency } from '~/enums/PaymentFrequency';

const getDefaultPriceByCurrency = (currency: Currency, paymentFrequency: PaymentFrequency) => {
    const pricesArray = [
        {
            id: 'DEFAULT_MONTHLY_VALUE_EUR',
            frequency: PaymentFrequency.MONTHLY,
            currency: Currency.EUR,
            value: DEFAULT_MONTHLY_VALUE_EUR,
        },
        {
            id: 'DEFAULT_MONTHLY_VALUE_USD',
            frequency: PaymentFrequency.MONTHLY,
            currency: Currency.USD,
            value: DEFAULT_MONTHLY_VALUE_USD,
        },
        {
            id: 'DEFAULT_YEARLY_VALUE_EUR',
            frequency: PaymentFrequency.YEARLY,
            currency: Currency.EUR,
            value: DEFAULT_YEARLY_VALUE_EUR,
        },
        {
            id: 'DEFAULT_YEARLY_VALUE_USD',
            frequency: PaymentFrequency.YEARLY,
            currency: Currency.USD,
            value: DEFAULT_YEARLY_VALUE_USD,
        },
        {
            id: 'DEFAULT_THREE_YEARS_VALUE_EUR',
            frequency: PaymentFrequency.THREE_YEARS,
            currency: Currency.EUR,
            value: DEFAULT_THREE_YEARS_VALUE_EUR,
        },
        {
            id: 'DEFAULT_THREE_YEARS_VALUE_USD',
            frequency: PaymentFrequency.THREE_YEARS,
            currency: Currency.USD,
            value: DEFAULT_THREE_YEARS_VALUE_USD,
        },
    ];

    const result = pricesArray.find((i) => i.currency === currency && i.frequency === paymentFrequency);

    return result?.value;
};

export default getDefaultPriceByCurrency;
