import useFetch from '~/hooks/useFetch';
import { checkProductNameFormat } from '~/utils/darwin-name';
import Nullable from '../../types/Nullable';

interface State<T> {
    data: T;
    error?: Error;
}

type UseFetchReturnType<T> = State<T> & { execute: () => void };

const PRODUCTQUOTES_MS_URL = '/api/productquotes';

export interface ProductQuotesResponse {
    productName: Nullable<string>;
    value: Nullable<string>;
    date: Nullable<string>;
    spread: Nullable<string>;
}

export const useProductQuotesService = () => {
    const useGetProductStartOfDayQuote = (productName: Nullable<string>): UseFetchReturnType<Nullable<number>> => {
        const { data, error, execute } = useFetch<Nullable<number>>(
            `${PRODUCTQUOTES_MS_URL}/historical/${productName}/start-of-day`,
        );
        return {
            data: data || null,
            error,
            execute: () => {
                if (productName) {
                    checkProductNameFormat(productName);
                    execute();
                }
            },
        };
    };

    const useGetProductQuoteInfo = (
        productName: Nullable<string> | Array<string>,
    ): UseFetchReturnType<Array<ProductQuotesResponse>> => {
        const { data, error, execute } = useFetch<Array<ProductQuotesResponse>>(
            `${PRODUCTQUOTES_MS_URL}/quotes/name?productName=${productName ? productName.toString() : null}`,
        );
        return {
            data: data || [],
            error,
            execute: () => {
                execute();
            },
        };
    };

    return { getProductQuoteInfo: useGetProductQuoteInfo, getProductInitialDayQuote: useGetProductStartOfDayQuote };
};
