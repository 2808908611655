import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import { useI18N } from '~/contexts/NextI18NProvider';
import useWindowSize from '~/hooks/useWindowSize';

import { BREAKPOINT_MAP, IconChevronRounded } from '@darwinex/components-library';

// import bannerDx from '~/public/images/banner-dx.svg';
import bannerDxAlt from '~/public/images/banner-dx-alt.svg';
import bannerDxMobile from '~/public/images/banner-dx-mobile.svg';

import styles from './BannerDx.module.scss';

const BannerDx = () => {
    const { M, ULink } = useI18N();
    const { width } = useWindowSize();
    const isMobile = width < BREAKPOINT_MAP.breakpoint_lg;

    return (
        <section className={classNames(styles.bannerDx, 'dxcl-container')}>
            <div className={classNames('dxcl-row dx-mb-5')}>
                <div className={classNames('dxcl-col-xl-24')}>
                    <section className={classNames(styles.bannerDx__card)}>
                        {!isMobile && (
                            <a href="https://darwinex.com" className={classNames(styles.bannerDx__link_block)}></a>
                        )}
                        <div className={classNames('dxcl-row')}>
                            <div className={classNames('dxcl-col-lg-12')}>
                                <div className={classNames(styles.bannerDx__wrapper_card)}>
                                    <p className={classNames(styles.bannerDx__title, 'dx-font-size-h1')}>
                                        {M('page.home.index.banner4.title')}
                                    </p>
                                    <p className={styles.bannerDx__subtitle}>{M('page.home.index.banner4.subtitle')}</p>
                                    <p className={classNames(styles.bannerDx__link)}>
                                        <a href="https://darwinex.com" target="_blank" rel="noopener noreferrer">
                                            {M('page.home.index.banner4.link')}
                                        </a>
                                        <IconChevronRounded
                                            className={classNames(styles.bannerDx__chevron)}
                                            width="20"
                                            height="20"
                                        />
                                    </p>
                                    {!isMobile && (
                                        <p className={styles.bannerDx__risk}>{M('page.home.index.banner4.risk')}</p>
                                    )}
                                </div>
                            </div>
                            <div className={classNames('dxcl-col-lg-12 d-flex flex-column justify-content-center')}>
                                <div className={styles.bannerDx__figure}>
                                    {!isMobile ? (
                                        <Image
                                            quality={100}
                                            alt="zero screen"
                                            src={bannerDxAlt}
                                            className={styles.bannerDx__screen}
                                            unoptimized
                                            style={{
                                                maxWidth: '100%',
                                                // height: 'auto',
                                            }}
                                        />
                                    ) : (
                                        <Image
                                            quality={100}
                                            alt="zero screen alt"
                                            src={bannerDxMobile}
                                            className={styles.bannerDx__screen}
                                            unoptimized
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    )}
                                </div>
                                {isMobile && (
                                    <span className={styles.bannerDx__risk}>{M('page.home.index.banner4.risk')}</span>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
};

export default BannerDx;
