import React, { useState } from 'react';
import Nullable from '~/types/Nullable';
import { Modals } from '~/enums/modals';
import { Currency } from '~/enums/Currency';
import SectionFeatures from './components/sectionFeatures/SectionFeatures';
import Growth from './components/growth/Growth';
import Hero from './components/hero/Hero';
import Millions, { CardProductInfo } from './components/millions/Millions';
import Banner from './components/banner/Banner';
import Trading from './components/trading/Trading';
import BannerDx from './components/banner-darwinex/BannerDx';
import VideoTutorial from '../../platform/darwin-page/modals/video-tutorial/VideoTutorial';

interface Props {
    darwinCards: Array<CardProductInfo>;
    competitionSilverMinAllocation: Nullable<number>;
    competitionSilverMaxAllocation: Nullable<number>;
    showFCAInfo: Nullable<boolean>;
    requestOriginCountry: Nullable<string>;
    userCurrency: Currency;
}

const IndexView = ({
    darwinCards,
    competitionSilverMinAllocation,
    competitionSilverMaxAllocation,
    showFCAInfo,
    requestOriginCountry,
    userCurrency,
}: Props) => {
    const [selectedModal, setSelectedModal] = useState<Nullable<string>>(null);

    const renderSelectedModal = () => {
        if (selectedModal === Modals.PLATFORM_REVIEW) {
            return (
                <VideoTutorial
                    onModalClose={() => setSelectedModal(null)}
                    title="The Strength of the Darwinex Zero Platform"
                    url="QibLtOy-1So"
                />
            );
        }
        return null;
    };

    return (
        <div className="page homepage">
            <Hero seePlatformReview={() => setSelectedModal(Modals.PLATFORM_REVIEW)} userCurrency={userCurrency} />
            <SectionFeatures />
            <Banner showFCAInfo={showFCAInfo} />
            <Trading
                competitionSilverMaxAllocation={competitionSilverMaxAllocation}
                competitionSilverMinAllocation={competitionSilverMinAllocation}
            />
            {showFCAInfo && <BannerDx />}
            <Millions products={darwinCards} />
            <Growth requestOriginCountry={requestOriginCountry} userCurrency={userCurrency} />
            {renderSelectedModal()}
        </div>
    );
};

export default IndexView;
