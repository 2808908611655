import { GetServerSidePropsContext } from 'next';
import React, { ReactElement, useEffect, useState } from 'react';

import { NextPageWithLayout } from '~/types';
import { getPageCopies, i18nNamespaceTypes } from '~/i18n/getPageCopies';
import HomeLayout from '~/components/layouts/HomeLayout';
import IndexView from '~/storybook/pages/home/index/Index.view';
import Head from 'next/head';
import { useI18N } from '~/contexts/NextI18NProvider';
import { useProductsService } from '~/hooks/services/useProductsService';
import { CardProductInfo } from '~/storybook/pages/home/index/components/millions/Millions';
import { useProductQuotesService } from '~/hooks/services/useProductQuotesService';
import { useRouter } from 'next/router';
import { usePerformanceFeesService } from '~/hooks/services/usePerformanceFeesService';
import { useProfile } from '~/contexts/NextProfileProvider';
import { isUserForbiddenFromViewingFCAInfo } from '~/utils/regulated-companies';
import { useCompetitionService } from '~/hooks/services/useCompetitionService';
import { useCurrentCompetitionInfo } from '~/contexts/CurrentCompetitionProvider';
import { CompetitionTypes } from '~/enums/competition-types';

import Nullable from '~/types/Nullable';
import ConsulClient from '~/common/consul';
import getCurrencyByCountry from '~/utils/currencies/default-currency';
import { getAmountWithCurrencyFormat } from '~/utils/format';
import getDefaultPriceByCurrency from '~/utils/currencies/default-price';
import { PaymentFrequency } from '~/enums/PaymentFrequency';

interface HomeProps {
    requestOriginCountry: Nullable<string>;
    countriesWithDefaultCurrencyEUR: string[];
}

const Home: NextPageWithLayout<HomeProps> = ({ countriesWithDefaultCurrencyEUR, requestOriginCountry }) => {
    const router = useRouter();

    const DARWIN_CARDS_PRODUCTS: Array<{
        productName: string;
        flag: 'china' | 'spain' | 'switzerland' | 'japan' | 'finland';
    }> = [
        {
            productName: 'THA.5.12',
            flag: 'finland',
        },
        {
            productName: 'JTL.5.15',
            flag: 'japan',
        },
        {
            productName: 'SYO.5.24',
            flag: 'spain',
        },
        {
            productName: 'ERQ.5.4',
            flag: 'china',
        },
    ];

    const { profile } = useProfile();

    useEffect(() => {
        if (router.isReady) {
            executeProduct1BasicInfoRequest();
            executeProduct2BasicInfoRequest();
            executeProduct3BasicInfoRequest();
            executeProduct4BasicInfoRequest();
            executeProductQuotesRequest();
            executecompetitionSilverAllocationDataRequest();
        }
    }, [router.isReady]);

    const { M } = useI18N();
    const { getProductBasicInfo } = useProductsService();
    const { getProductQuoteInfo } = useProductQuotesService();
    const { getTraderEarnings } = usePerformanceFeesService();
    const { currentCompetitionDate } = useCurrentCompetitionInfo();
    const { getCompetitionAllocationInfo } = useCompetitionService();

    const [product1Owner, setProduct1Owner] = useState<Nullable<string>>(null);
    const [product2Owner, setProduct2Owner] = useState<Nullable<string>>(null);
    const [product3Owner, setProduct3Owner] = useState<Nullable<string>>(null);
    const [product4Owner, setProduct4Owner] = useState<Nullable<string>>(null);

    const {
        data: product1BasicInfo,
        status: product1BasicInfoRequestStatus,
        execute: executeProduct1BasicInfoRequest,
    } = getProductBasicInfo(DARWIN_CARDS_PRODUCTS[0].productName);

    const {
        data: product2BasicInfo,
        status: product2BasicInfoRequestStatus,
        execute: executeProduct2BasicInfoRequest,
    } = getProductBasicInfo(DARWIN_CARDS_PRODUCTS[1].productName);

    const {
        data: product3BasicInfo,
        status: product3BasicInfoRequestStatus,
        execute: executeProduct3BasicInfoRequest,
    } = getProductBasicInfo(DARWIN_CARDS_PRODUCTS[2].productName);

    const {
        data: product4BasicInfo,
        status: product4BasicInfoRequestStatus,
        execute: executeProduct4BasicInfoRequest,
    } = getProductBasicInfo(DARWIN_CARDS_PRODUCTS[3].productName);

    const { data: productsQuoteInfo, execute: executeProductQuotesRequest } = getProductQuoteInfo(
        DARWIN_CARDS_PRODUCTS.map((product) => product.productName),
    );

    const { data: product1EarningsData, execute: executeProduct1EarningsRequest } = getTraderEarnings(product1Owner);

    const { data: product2EarningsData, execute: executeProduct2EarningsRequest } = getTraderEarnings(product2Owner);

    const { data: product3EarningsData, execute: executeProduct3EarningsRequest } = getTraderEarnings(product3Owner);

    const { data: product4EarningsData, execute: executeProduct4EarningsRequest } = getTraderEarnings(product4Owner);

    const { data: competitionSilverAllocationData, execute: executecompetitionSilverAllocationDataRequest } =
        getCompetitionAllocationInfo(CompetitionTypes.SILVER, currentCompetitionDate);

    useEffect(() => {
        if (product1BasicInfoRequestStatus === 200) setProduct1Owner(product1BasicInfo.username);
    }, [product1BasicInfoRequestStatus]);

    useEffect(() => {
        if (product2BasicInfoRequestStatus === 200) setProduct2Owner(product2BasicInfo.username);
    }, [product2BasicInfoRequestStatus]);

    useEffect(() => {
        if (product3BasicInfoRequestStatus === 200) setProduct3Owner(product3BasicInfo.username);
    }, [product3BasicInfoRequestStatus]);

    useEffect(() => {
        if (product4BasicInfoRequestStatus === 200) setProduct4Owner(product4BasicInfo.username);
    }, [product4BasicInfoRequestStatus]);

    useEffect(() => {
        if (product1Owner) executeProduct1EarningsRequest();
    }, [product1Owner]);

    useEffect(() => {
        if (product2Owner) executeProduct2EarningsRequest();
    }, [product2Owner]);

    useEffect(() => {
        if (product3Owner) executeProduct3EarningsRequest();
    }, [product3Owner]);

    useEffect(() => {
        if (product4Owner) executeProduct4EarningsRequest();
    }, [product4Owner]);

    const getProductEarnings = (owner: Nullable<string>): Nullable<number> => {
        if (product1EarningsData?.username === owner) return product1EarningsData.earnings;
        if (product2EarningsData?.username === owner) return product2EarningsData.earnings;
        if (product3EarningsData?.username === owner) return product3EarningsData.earnings;
        if (product4EarningsData?.username === owner) return product4EarningsData.earnings;
        return null;
    };

    const getProductCardsInfo = (): Array<CardProductInfo> => {
        const selectedProductsBasicInfo = [product1BasicInfo, product2BasicInfo, product3BasicInfo, product4BasicInfo];
        return DARWIN_CARDS_PRODUCTS.map((product) => {
            const productBasicInfo = selectedProductsBasicInfo.find((p) => p.productName === product.productName);
            const productQuoteInfo = productsQuoteInfo.find(
                (productQuote) => productQuote.productName === product.productName,
            );
            const productEarnings = getProductEarnings(productBasicInfo?.username || null);
            return {
                productName: product.productName,
                owner: productBasicInfo?.username || null,
                flag: product.flag,
                firstQuoteDate: productBasicInfo?.firstQuoteDate || null,
                providerEarnings: productEarnings,
                quote: productQuoteInfo ? Number(productQuoteInfo.value) : null,
                returnSinceInception: productBasicInfo?.productReturn || null,
                totalInvestment: productBasicInfo?.invested || null,
                totalInvestors: productBasicInfo?.investors || null,
            };
        });
    };

    let title = 'Darwinex Zero - ';
    title += M('page.seo.title') as string;

    const showFCAInfo = !isUserForbiddenFromViewingFCAInfo([
        requestOriginCountry,
        profile?.country,
        profile?.KYS?.info?.address?.country,
    ]);

    const getMaxAllocation = (): Nullable<number> => {
        if (competitionSilverAllocationData.specialPositions.prizesDistribution.length > 0) {
            const firstPosition = competitionSilverAllocationData.specialPositions.prizesDistribution.find(
                (posInfo) => posInfo.position === 1,
            );
            return firstPosition?.allocation || null;
        }
        return null;
    };

    const currencyByCountryIp = getCurrencyByCountry(countriesWithDefaultCurrencyEUR, requestOriginCountry);
    const seoDescription = M('page.seo.description', {
        price: getAmountWithCurrencyFormat(
            getDefaultPriceByCurrency(currencyByCountryIp, PaymentFrequency.MONTHLY),
            currencyByCountryIp,
        ),
    }) as string;

    return (
        <>
            <Head>
                <title>{title ?? ''}</title>
                <meta name="description" content={seoDescription} key="description" />
                <meta property="og:title" content={title ?? ''} key="title" />
                <meta property="og:description" content={seoDescription} key="og_description" />
            </Head>
            <IndexView
                darwinCards={getProductCardsInfo()}
                competitionSilverMaxAllocation={getMaxAllocation()}
                competitionSilverMinAllocation={competitionSilverAllocationData.minAllocation}
                showFCAInfo={showFCAInfo}
                requestOriginCountry={requestOriginCountry}
                userCurrency={currencyByCountryIp}
            />
        </>
    );
};

Home.getLayout = function getLayout(page: ReactElement) {
    return <HomeLayout noMenu>{page}</HomeLayout>;
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const __messages = await getPageCopies(context.locales, i18nNamespaceTypes.HOME, 'index');
    const consul = ConsulClient.getInstance();
    const countriesWithDefaultCurrencyEUR = await consul.getValue('default-currency/EUR');
    return {
        props: {
            __messages,
            requestOriginCountry: context.req.headers['cloudfront-viewer-country'] || null,
            countriesWithDefaultCurrencyEUR:
                countriesWithDefaultCurrencyEUR !== null ? countriesWithDefaultCurrencyEUR.split(',') : [],
        },
    };
};

export default Home;
